import React, { memo, useContext } from 'react';
import { headingStyle, isItemVisible, safetyCheck, visibleItems } from "../../../utils";
import PageContext from '../../../contexts/PageContext';
import { useSelector } from '../../../contexts/ResumeContext';
import parse from "html-react-parser";


const HobbyA = ({ id, name }) => (
  <div key={id} className="text-sm myLi">
    {parse(name)}
  </div>
);

const HobbiesA = () => {
  const { data, heading: Heading } = useContext(PageContext);
  const variableTemplate = data.metadata.template;

  return safetyCheck(data.hobbies) ? (
    <div className={(visibleItems(data.hobbies.items)) ? "module hobbies" : "erase"}>
      <Heading parent='hobbies'>{visibleItems(data.hobbies.items) && data.hobbies.heading}</Heading>
      <div className="grid gap-1">
        {data.hobbies.items.map((x) => isItemVisible(x) && HobbyA(x))}
      </div>
      <div style={headingStyle(data.hobbies.items)} className="cgUnderline"></div>
    </div>
  ) : null;
};

export default memo(HobbiesA);

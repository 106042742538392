import { get } from 'lodash';
import React, { memo, useContext } from 'react';
import { formatDate } from '../../../utils';
import Icons from '../Icons';
import PageContext from '../../../contexts/PageContext';

const BirthDateLight = ({ pos }) => {
  const { data } = useContext(PageContext);
  const Icon = get(Icons, 'birthday');
  const variableTemplate = data.metadata.template;
  const visible =(pos == "erase") ? "erase" : "text-xs flex items-center";

  if (data.profile.birthDate) {
    return (
      <div className={visible}>
        <div className="containIcon"
             style={{ backgroundColor: data.metadata[variableTemplate].primary}}>
          <Icon
          size="11px"
          className="mr-2"
          style={{ color: data.metadata[variableTemplate].background}}
        />
        </div>
        <span className="break-all">
          {formatDate({
            date: data.profile.birthDate,
            language: data.metadata.language,
            includeDay: true,
          })}

        </span>
      </div>
    );
  }

  return null;
};

export default memo(BirthDateLight);

import React, { memo, useContext } from 'react';
import { hexToRgb } from "../../../utils";
import PageContext from '../../../contexts/PageContext';
import { useSelector } from "../../../contexts/ResumeContext";

const HeadingCast = ({ parent,children }) => {
  const { data } = useContext(PageContext);
  const variableTemplate = data.metadata.template;
  const myColor = data.metadata.castform.primary;

   const { r, g, b } = hexToRgb(myColor) || {};
  /* const { r, g, b } = hexToRgb('333333') || {};
  const { r, g, b } = hexToRgb(useSelector(`metadata.${variableTemplate}.primary`)) || {};*/
  return (
    <div className="px-4 rounded-r leading-loose font-bold text-xs uppercase tracking-wide mb-3 title">
      <h6 style={{color: data.metadata.colors.background,
        background:`linear-gradient(90deg,rgba(${r - 40}, ${g - 40}, ${b - 40},1) 50%, rgba(${r - 40}, ${g - 40}, ${b - 40},0) 98%)`}}>
        {children}</h6>
      <div className="triaTitle" style={{borderColor:`rgb(${r - 80}, ${g - 80}, ${b - 80})`}}></div>
    </div>
  );
 /* return (
    <div className="px-4 rounded-r leading-loose font-bold text-xs uppercase tracking-wide mb-3 title">
      <h6 style={{color: useSelector(`metadata.${variableTemplate}.background`),
        background:myColor,
        borderColor:myColor}}>
        {children}</h6>
      <div className="triaTitle"></div>
    </div>
  );*/

};

export default memo(HeadingCast);

import { useTranslation } from 'react-i18next';
import React, { memo, useContext } from 'react';
import PageContext from '../../../contexts/PageContext';
import { formatDateRange, isItemVisible, visibleItems, safetyCheck, headingStyle } from "../../../utils";
import parse from 'html-react-parser';



// items-center retiré de la div 1
const WorkItem = ({ item, language }) => {
  const { t } = useTranslation();
  const {data} = useContext(PageContext);
  const variableTemplate = data.metadata.template;
  const layout = data.metadata.layout[variableTemplate];
  const whatCol = layout[0].indexOf('work');
  const myColor = (whatCol == "-1") ? data.metadata[variableTemplate].primary : data.metadata[variableTemplate].background;

  return (
    <div className="ref">
      <div className="flex justify-between reference"> 
        <div className="flex flex-col text-left mr-2">
          <h6 className="font-bold text-sm">{item.company}</h6>
          <span className="text-xs">{item.position}</span>
        </div>
        {item.startDate && (
          <h6 className="text-xs font-bold text-right" style={{
            color: myColor
          }} >
            {formatDateRange(
              {
                startDate: item.startDate,
                endDate: item.endDate,
                language,
              },
              t,
            )}
          </h6>
        )}
      </div>
      {item.summary && (
        <div className="mt-2 text-sm">{parse(item.summary)}</div>
      )}
      <div className="underline" style={{
               borderColor: myColor
             }}></div>
    </div>
  );
};

const WorkPik = () => {
  const { data, heading: Heading } = useContext(PageContext);


  return safetyCheck(data.work) ? (
    <div className={(visibleItems(data.work.items)) ? "module work" : "erase"}>
         <Heading parent='work'>{visibleItems(data.work.items) && data.work.heading}</Heading>
      <div className="grid gap-4">
        {data.work.items.map(
          (x) => isItemVisible(x) && <WorkItem key={x.id} item={x} language={data.metadata.language} />
          )}
      </div>
      <div style={headingStyle(data.work.items)} className="cgUnderline"></div>
    </div>
  ) : null;
};

export default memo(WorkPik);

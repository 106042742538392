import { useTranslation } from 'react-i18next';
import React, { memo, useContext } from 'react';
import PageContext from '../../../contexts/PageContext';
import { formatDateRange, isItemVisible, safetyCheck, visibleItems } from "../../../utils";
import parse from "html-react-parser";

const ProjectItem = ({ item, language }) => {
  const { t } = useTranslation();
  const {data} = useContext(PageContext);
  const variableTemplate = data.metadata.template;

  return (
    <div className="ref">
      <div className="reference">
        <h6 className='text-sm font-bold'>{item.title}</h6>
        {item.startDate && (
        <h6 className="text-xs font-bold text-right wdate">
          {formatDateRange(
            {
              startDate: item.startDate,
              endDate: item.endDate,
              language,
            },
            t,
          )}
        </h6>
      )}
      </div>
      <div>
      {item.link && (
          <a href={item.link} className="text-xs" style={{color: data.metadata[variableTemplate].primary}}>
            {item.link}
          </a>
        )}
        {item.summary && (
          <div className="text-sm">{parse(item.summary)}</div>
        )}
      </div>
    </div>
  );
};

const ProjectsStel = () => {
  const { data, heading: Heading } = useContext(PageContext);

  return safetyCheck(data.projects) ? (
    <div className={(visibleItems(data.projects.items)) ? "module projects" : "erase"}>
      <Heading parent='languages'>{data.projects.heading}</Heading>
      <div className="grid gap-1">
        {data.projects.items.map(
          (x) =>
            isItemVisible(x) && (
              <ProjectItem
                key={x.id}
                item={x}
                language={data.metadata.language}
              />
            ),
        )}
      </div>
    </div>
  ) : null;
};

export default memo(ProjectsStel);

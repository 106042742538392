import React, { memo, useContext } from 'react';
import {visibleItems, isItemVisible, safetyCheck } from '../../../utils';
import PageContext from '../../../contexts/PageContext';
import {FaChartLine} from 'react-icons/fa';
import { CircularGauge } from "@progress/kendo-react-gauges";

const SkillItem = ({ name, level }) => {
  const {data} = useContext(PageContext);
  const variableTemplate = data.metadata.template;
  const myLvl = level*20;
  const colors = [{color: data.metadata[variableTemplate].primary}];
  const arcOptions = {
    value: myLvl,
    colors
  };
  const centerRenderer = (value) => {
    return(
      <h3 style={{color: colors }}>{value}%</h3>
    );
  };

  return (
  <div  className="gauge">
    <h6 className="font-bold text-sm">{name}</h6>
    <div className='box'>
      <CircularGauge {...arcOptions} centerRender={centerRenderer} style={{width:"50px", height:"50px"}} />
    </div>
  </div>
  );
};

const SkillsDra = () => {
  const { data } = useContext(PageContext);
  const variableTemplate = data.metadata.template;
  const Icon = FaChartLine;


  return safetyCheck(data.skills) ? (
    <div className={(visibleItems(data.skills.items)) ? "module skills" : "erase"}>
      {visibleItems(data.skills.items) &&
        <div className='flex items-center'>
          <Icon
            size="1.15em"
            className="mr-2"
            style={{ color: data.metadata[variableTemplate].primary }}
          />
          <h6 className="text-lg font-bold uppercase"
              style={{ color: data.metadata[variableTemplate].text }}>
            {data.skills.heading}
          </h6>
        </div>
      }
        <div className="grid grid-cols-2 gap-1 contain">
      {data.skills.items.map((x) => isItemVisible(x) && SkillItem(x))}
        </div>
    </div>
  ) : null;
};

export default memo(SkillsDra);

import React, { useState } from 'react';

const AsyncImage = ({ src, alt, onImageLoad }) => {
  const [loaded, setLoaded] = useState(false);

  const handleImageLoad = () => {
    setLoaded(true);
    //onImageLoad();
  };

  return (
    <img
      src={src}
      alt={alt}
      onLoad={handleImageLoad}
      style={{ display: loaded ? 'block' : 'none' }}
    />
  );
};

export default AsyncImage;

import React, { memo, useContext } from 'react';
import { safetyCheck } from "../../../utils";
import PageContext from '../../../contexts/PageContext';
import parse from 'html-react-parser';

const ObjectiveRock = () => {
  const { data, heading: Heading } = useContext(PageContext);
    const variableTemplate = data.metadata.template;
  /*colG or colD*/
  const layout = data.metadata.layout[variableTemplate];
  const whatCol = layout[0].indexOf("objective");
  const myBorder = (whatCol == "-1") ? "cgUnderNone" : "cgUnderline";


  return (
    safetyCheck(data.objective, 'body') && (
      <div className={((data.objective.body).length > 7) ? "module objective" : "erase"}>
        {((data.objective.body).length > 7) && <Heading parent='objective'>{data.objective.heading}</Heading>}
        <div className="text-sm">{parse(data.objective.body)}</div>
        <div className={myBorder} style={{borderColor: data.metadata[variableTemplate].text}}></div>

      </div>
    )
  );
};

export default memo(ObjectiveRock);

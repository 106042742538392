import { useTranslation } from 'react-i18next';
import React, { memo, useContext } from 'react';
import PageContext from '../../../contexts/PageContext';
import { formatDateRange, isItemVisible, safetyCheck, visibleItems } from '../../../utils';
import {FaBookOpen} from 'react-icons/fa';
import parse from "html-react-parser";

const EducationItem = ({ item, language }) => {
  const { t } = useTranslation();
  const {data} = useContext(PageContext);
  const variableTemplate = data.metadata.template;

  return (
    <div className="ref">
      <div className="module">
        <div className="circle" style={{backgroundColor: data.metadata[variableTemplate].primary}}></div>
        <h6 className="font-bold text-sm wtitle">{item.degree} {item.field}</h6>
        <div className="wdate">
          {item.startDate && (
            <h6 className="text-xs text-right">
              {formatDateRange(
                {
                  startDate: item.startDate,
                  endDate: item.endDate,
                  language,
                },
                t,
              )}
            </h6>
          )}
          <div className="text-sm font-bold">{item.gpa && (<span>Mention : {item.gpa}</span>)}</div>
        </div>
      </div>
      <span className="text-xs">{item.institution}</span>
      <div className="wcontent" style={{borderColor: data.metadata[variableTemplate].primary}}>
        {item.summary.length > 7 && (
          <div className="mt-2 text-sm">{parse(item.summary)}</div>
        )}
      </div>
    </div>
  );
};

const EducationLine= () => {
  const { data } = useContext(PageContext);
  const Icon = FaBookOpen;
  const variableTemplate = data.metadata.template;

  return safetyCheck(data.education) ? (
    <div className={(visibleItems(data.education.items)) ? "module education" : "erase"}>
      <div className='flex items-center'>
        <Icon
          size="1.15em"
          className="mr-2"
          style={{ color: data.metadata[variableTemplate].primary}}
        />
        <h6 className="text-lg font-bold uppercase" style={{ color: data.metadata[variableTemplate].text}} >
        {data.education.heading}
        </h6>
      </div>
      <div className="grid gap-2 contain">
        {data.education.items.map(
          (x) =>
            isItemVisible(x) && (
              <EducationItem
                key={x.id}
                item={x}
                language={data.metadata.language}
              />
            ),
        )}
      </div>
    </div>
  ) : null;
};

export default memo(EducationLine);

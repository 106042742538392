import { FaCaretRight } from 'react-icons/fa';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import React, { memo, useContext } from 'react';
import { hasAddress, isItemVisible, safetyCheck } from '../../../utils';
import BirthDateWind from '../BirthDate/BirthDateWind';
import Icons from '../Icons';
import PageContext from '../../../contexts/PageContext';


const ContactItem = ({ value, icon, link }) => {
  const { data } = useContext(PageContext);
  const Icon = get(Icons, icon && icon.toLowerCase(), FaCaretRight);
  const variableTemplate = data.metadata.template;

  return value ? (
    <div className="flex items-center">
      <Icon
        size="11px"
        className="mr-2"
        style={{ color: data.metadata[variableTemplate].primary}}
      />
      {link ? (
        <a href={link} target="_blank" rel="noopener noreferrer">
          <span className="break-all">{value}</span>
        </a>
      ) : (
        <span className="">{value}</span>
      )}
    </div>
  ) : null;
};

const ContactWind = () => {
  const { t } = useTranslation();
  const { data, heading: Heading } = useContext(PageContext);

  return (
    <div className="module contact">
      <Heading parent='languages'>{data.profile.heading}</Heading>
    <div className="text-xs grid gap-1 text-xs">

      <ContactItem
        label={t('shared.forms.email')}
        value={data.profile.email}
        icon="email"
        link={`mailto:${data.profile.email}`}
      />
      <div className="text-lg">
        <ContactItem
          label={t('shared.forms.phone')}
          value={data.profile.phone}
          icon="phone"
          link={`tel:${data.profile.phone}`}
        />
      </div>
      <BirthDateWind />
      <div className='permis'>
        <ContactItem
          label={t('shared.forms.drivingLicence')}
          value={data.profile.drivingLicence}
          icon="driving"
        />
        <ContactItem
          label={t('shared.forms.mobility')}
          value={`Mobilité : ${data.profile.mobility}`}
          icon="mobility"
        />
      </div>
      <div className='reseaux'>
        {safetyCheck(data.social) &&
        data.social.items.map(
          (x) =>
            isItemVisible(x) && (
              <ContactItem
                key={x.id}
                value={x.username}
                icon={x.network}
                link={x.url}
              />
            ),
        )}
      </div>
      {hasAddress(data.profile.address) && (
        <ContactItem
          label={t('shared.forms.address')}
          value={`${[
            data.profile.address.line1,
            data.profile.address.line2,
            data.profile.address.city,
          ]
            .filter(Boolean)
            .join(', ')} ${data.profile.address.pincode}`}
          icon="address"
        />
      )}
      </div>
    </div>
  );
};

export default memo(ContactWind);

import React, { memo, useContext } from 'react';
import { isItemVisible, safetyCheck, visibleItems } from "../../../utils";
import PageContext from '../../../contexts/PageContext';
import { ArcGauge } from "@progress/kendo-react-gauges";

const SkillItem = ({ name, level }) => {
  const {data} = useContext(PageContext);
  const variableTemplate = data.metadata.template;
  const layout = data.metadata.layout[variableTemplate];
  const whatCol = layout[0].indexOf("skills");
  const colors = (whatCol == "-1") ? [{color:data.metadata[variableTemplate].primary}] : [{color:data.metadata[variableTemplate].text}];

  var lvl = level*20;
  const arcOptions = {
    value: lvl,
    colors
  };
  const arcCenterRenderer = (value) => {
    return(
      <h3 style={{color: colors }}>{value}%</h3>
    );
  };
  return (
    <div className="gauge">
      <h6 className="font-semibold text-xs">{name}</h6>
      <div className='box'>
        <ArcGauge {...arcOptions} arcCenterRender={arcCenterRenderer} style={{width:"80px", height:"50px"}}/>
      </div>
    </div>
  );
};

const SkillsP = () => {
  const { data, heading: Heading } = useContext(PageContext);
  const variableTemplate = data.metadata.template;
  const layout = data.metadata.layout[variableTemplate];
  const whatCol = layout[0].indexOf("skills");
  const myBlock = (whatCol == "-1") ? "grid grid-cols-3 gap-2 gaugeContain" : "grid grid-cols-2 gap-2 gaugeContain skillsContain";


  return safetyCheck(data.skills) ? (
    <div className={(visibleItems(data.skills.items)) ? "module skills" : "erase"}>
      <Heading parent='skills'>{visibleItems(data.skills.items) && data.skills.heading}</Heading>
      <div className={myBlock}>
        {data.skills.items.map((x) => isItemVisible(x) && SkillItem(x))}
      </div>
    </div>
  ) : null;
};

export default memo(SkillsP);
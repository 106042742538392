import React, { memo, useContext } from 'react';
import { hexToRgb } from '../../../utils';
import PageContext from '../../../contexts/PageContext';

const HeadingC = ({ children }) => {
  const { data } = useContext(PageContext);
  const variableTemplate = data.metadata.template;
  const gradColor = data.metadata[variableTemplate].primary;
  const { r, g, b } = hexToRgb(gradColor) || {};

  return (
    <div className='mb-2 tracking-wide headingContain' style={{ background: `rgba(${r}, ${g}, ${b}, 0.07)` }}>
      <div className='heading'>
        <h6 className="text-lg uppercase tracking-wide title" style={{color: data.metadata[variableTemplate].primary,fontWeight:"700"}}>
          {children}
        </h6>
      </div>
    </div>
  );
};

export default memo(HeadingC);

import React, { memo, useContext } from 'react';
import { headingStyle, isItemVisible, safetyCheck, visibleItems } from "../../../utils";
import PageContext from '../../../contexts/PageContext';

const LanguageItem = ({ id, name, fluency }) => (
  <div key={id} className="">
    <h6 className="font-bold text-sm">{name}</h6>
    <span className="text-xs">{fluency}</span>
  </div>
);

const LanguagesPik = () => {
  const { data, heading: Heading } = useContext(PageContext);
  const variableTemplate = data.metadata.template;

  /*colG or colD*/
  const layout = data.metadata.layout[variableTemplate];
  const whatCol = layout[0].indexOf("languages");
  const myBlock = (whatCol == "-1") ? "grid grid-cols-4 gap-1" : "grid grid-cols-2 gap-1";

  return safetyCheck(data.languages) ? (
    <div className={(visibleItems(data.languages.items)) ? "module language" : "erase"}>
    <Heading parent='languages'>{visibleItems(data.languages.items) && data.languages.heading}</Heading>
    <div className={myBlock}>
      {data.languages.items.map((x) => isItemVisible(x) && LanguageItem(x))}
    </div>
    <div style={headingStyle(data.languages.items)} className="cgUnderline"></div>
  </div>
) : null;
};

export default memo(LanguagesPik);



import React, { memo, useContext } from 'react';
import { isItemVisible, safetyCheck, visibleItems } from "../../../utils";
import PageContext from '../../../contexts/PageContext';
import {FaUserTie} from 'react-icons/fa';
import parse from "html-react-parser";

var myBlock;

const ReferenceItem = ({ id, name, position, phone, email, summary }) => {
  const {data} = useContext(PageContext);
  const variableTemplate = data.metadata.template;

  /*colG or colD*/
  const layout = data.metadata.layout[variableTemplate];
  const whatCol = layout[0].indexOf("references");
  const myCircle = (whatCol == "-1") ? "circle" : "";
  const myTitle = (whatCol == "-1") ? "font-semibold text-sm wtitle" : "font-semibold text-sm";
  const myContent = (whatCol == "-1") ? "wcontent" : "";
  myBlock = (whatCol == "-1") ? "grid grid-cols-3 gap-4 contain" : "grid gap-4 contain";

  return(
  <div key={id} className="flex flex-col ref" style={{marginTop:"5px",}}>
    <div className="reference">
      <div className={myCircle} style={{backgroundColor: data.metadata[variableTemplate].primary}}></div>
      <h6 className={myTitle}>{name}</h6>
    </div>
    <div className={myContent} style={{borderColor: data.metadata[variableTemplate].primary}}>
      <span className="text-xs" style={{display:"block"}}>{position}</span>
      <span className="text-xs" style={{display:"block"}}>{phone}</span>
      <span className="text-xs" style={{display:"block"}}>{email}</span>
      {summary && (
        <div className="mt-2 text-sm">{parse(summary)}</div>
      )}
    </div>
  </div>
  );
};


const ReferencesDra = () => {
  const { data} = useContext(PageContext);
  const variableTemplate = data.metadata.template;
  const Icon = FaUserTie;
  
  return safetyCheck(data.references) ? (
    <div className={(visibleItems(data.references.items)) ? "module references" : "erase"}>
      <div className='flex items-center'>
        <Icon
          size="1.15em"
          className="mr-2"
          style={{ color: data.metadata[variableTemplate].primary}}
        />
        <h6 className="text-lg font-bold uppercase" style={{ color: data.metadata[variableTemplate].text}} >
        {data.references.heading}
        </h6>
      </div>
      <div className={myBlock}>
        {data.references.items.map((x) => isItemVisible(x) && ReferenceItem(x))}
      </div>
    </div>
  ) : ("");
};

export default memo(ReferencesDra);

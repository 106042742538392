import { useTranslation } from 'react-i18next';
import React, { memo, useContext } from 'react';
import PageContext from '../../../contexts/PageContext';
import { formatDateRange, headingStyle, isItemVisible, safetyCheck, visibleItems } from "../../../utils";
import parse from "html-react-parser";



const EducationItem = ({ item, language, }) => {
  const { t } = useTranslation();
  const {data} = useContext(PageContext);
  const variableTemplate = data.metadata.template;
  const layout = data.metadata.layout[variableTemplate];
  const whatCol = layout[0].indexOf('education');

  const myColor = (whatCol == "-1") ? data.metadata[variableTemplate].primary : data.metadata[variableTemplate].background;

  return (
    <div className="ref">
      <div className="flex justify-between items-center">
        <div className="flex flex-col text-left mr-2">
          <h6 className="text-sm"><strong>{item.institution}</strong></h6>
          <span className="text-xs">
            <strong>{item.degree}</strong> {item.field}
          </span>
        </div>
        <div className="flex flex-col items-end text-right">
          {item.startDate && (
            <h6 className="text-xs font-bold mb-1" style={{
              color: myColor
            }} >
              {formatDateRange(
                {
                  startDate: item.startDate,
                  endDate: item.endDate,
                  language,
                },
                t,
              )}
            </h6>
          )}
          <span className="text-sm font-medium">{item.gpa}</span>
        </div>
      </div>
      {item.summary.length > 7 && (
        <div className="mt-2 text-sm">{parse(item.summary)}</div>
      )}
      <div className="underline" style={{borderColor: myColor}}></div>
    </div>
  );
};

const EducationA = () => {
  const { data, heading: Heading } = useContext(PageContext);

  return safetyCheck(data.education) ? (
    <div className={(visibleItems(data.education.items)) ? "module education" : "erase"}>
      <Heading parent='education'>{visibleItems(data.education.items) && data.education.heading}</Heading>
      <div className="grid gap-2">
        {data.education.items.map(
          (x) =>
            isItemVisible(x) && (
              <EducationItem
                key={x.id}
                item={x}
                language={data.metadata.language}
                template={data.metadata.template}
              />
            ),
        )}
      </div>
      <div style={headingStyle(data.education.items)} className="cgUnderline"></div>
    </div>
  ) : null;
};

export default memo(EducationA);

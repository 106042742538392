import React, { memo, useContext } from 'react';
import { isItemVisible, safetyCheck, visibleItems } from "../../../utils";
import PageContext from '../../../contexts/PageContext';
import { ChunkProgressBar } from '@progress/kendo-react-progressbars';
import {useSelector} from "../../../contexts/ResumeContext";

const SkillItem = ({ id, name, level }) => {
  const {data} = useContext(PageContext);
  const variableTemplate = data.metadata.template;
  const layout = data.metadata.layout[variableTemplate];
  const whatCol = layout[0].indexOf("skills");
  const myContain = (whatCol == "-1") ? "progressAll2" : "progressAll";
  const chunks = 5;
  const emptyStyles = { background: 'e1e1e1' };
  const progressStyles = { background: useSelector(`metadata.${variableTemplate}.primary`) };
  const completeness =level*20;

  return (
    <div key={id} className={myContain}>
      <h6 className="font-bold text-xs">{name}</h6>
      <ChunkProgressBar
        value={completeness}
        emptyStyle={emptyStyles}
        progressStyle={progressStyles}
        chunkCount={chunks}
      />
    </div>
  );
};

const SkillsWind = () => {
  const { data, heading: Heading } = useContext(PageContext);
  const variableTemplate = data.metadata.template;
  const layout = data.metadata.layout[variableTemplate];
  const whatCol = layout[0].indexOf("skills");
  const myBlock = (whatCol == "-1") ? "grid grid-cols-2 gap-2" : "grid";

  return safetyCheck(data.skills) ? (
    <div className={(visibleItems(data.skills.items)) ? "module skills" : "erase"}>
      <Heading parent='skills'>{visibleItems(data.skills.items) && data.skills.heading}</Heading>
      <div className={myBlock}>
        {data.skills.items.map((x) => isItemVisible(x) && SkillItem(x))}
      </div>
    </div>
  ) : null;
};

export default memo(SkillsWind);
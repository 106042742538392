import React, { memo, useContext } from 'react';
import {visibleItems, safetyCheck } from "../../../utils";
import PageContext from '../../../contexts/PageContext';
import parse from 'html-react-parser';

const ObjectivePik = () => {
  const { data, heading: Heading } = useContext(PageContext);

  return (
    safetyCheck(data.objective, 'body') && (
      <div className={((data.objective.body).length > 7) ? "module objective" : "erase"}>
        {((data.objective.body).length > 7) && <Heading parent='objective'>{data.objective.heading}</Heading>}
        <div className="text-sm">{parse(data.objective.body)}</div>
        <div style={{paddingBottom: "8px", borderBottom: "1px solid #fff"}} className="cgUnderline"></div>
      </div>
    )
  );
};

export default memo(ObjectivePik);

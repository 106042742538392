import React, { memo, useContext } from 'react';
import { isItemVisible, safetyCheck, visibleItems } from "../../../utils";
import PageContext from '../../../contexts/PageContext';
import parse from "html-react-parser";
import {FaFutbol} from 'react-icons/fa';

const HobbyA = ({ id, name }) => {

  return(
    <div key={id} className="text-sm myLi">
      {parse(name)}
    </div>
  )
};

const HobbiesLine = () => {
  const { data} = useContext(PageContext);
  const variableTemplate = data.metadata.template;
  const Icon = FaFutbol;

  return safetyCheck(data.hobbies) ? (
    <div className={(visibleItems(data.hobbies.items)) ? "module hobbies" : "erase"}>
      <div className='flex items-center'>
        <Icon
          size="1.15em"
          className="mr-2"
          style={{ color: data.metadata[variableTemplate].primary}}
        />
        <div className="font-bold uppercase text-lg " style={{color: data.metadata[variableTemplate].text}}>
          {data.hobbies.heading}</div>
      </div>
      <div className="grid wcontent" style={{borderColor: data.metadata[variableTemplate].primary}}>
        {data.hobbies.items.map((x) => isItemVisible(x) && HobbyA(x))}
      </div>
    </div>
  ) : null;
};

export default memo(HobbiesLine);

import React, { memo, useContext } from 'react';
import { safetyCheck } from "../../../utils";
import PageContext from '../../../contexts/PageContext';
import { FaBinoculars} from 'react-icons/fa';
import parse from "html-react-parser";

const ObjectiveLine = () => {
  const { data } = useContext(PageContext);
  const Icon = FaBinoculars;
  const variableTemplate = data.metadata.template;

  return (
    safetyCheck(data.objective, 'body') && (
      <div className={((data.objective.body).length > 7) ? "module objective" : "erase"}>
        <div className='flex items-center'>
          <Icon
          size="1.15em"
          className="mr-2"
          style={{ color: data.metadata[variableTemplate].primary}}
        />
          <h6 className="text-lg font-bold uppercase" style={{ color: data.metadata[variableTemplate].text}} >{data.objective.heading}</h6>
        </div>
        <div className="dcontent contain" style={{borderColor: data.metadata[variableTemplate].primary}} >
        <div className="text-sm" >{parse(data.objective.body)}</div>
        </div>
      </div>
    )
  );
};

export default memo(ObjectiveLine);

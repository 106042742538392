import { useTranslation } from 'react-i18next';
import React, { memo, useContext } from 'react';
import PageContext from '../../../contexts/PageContext';
import { formatDateRange, isItemVisible, safetyCheck, visibleItems } from "../../../utils";
import { FaCopy} from 'react-icons/fa';
import { useSelector } from '../../../contexts/ResumeContext';
import parse from "html-react-parser";

const ProjectItem = ({ item, language }) => {
  const { t } = useTranslation();
  const {data} = useContext(PageContext);
  const variableTemplate = data.metadata.template;
  /*colG or colD*/
  const layout = data.metadata.layout[variableTemplate];
  const whatCol = layout[0].indexOf("projects");
  const myCircle = (whatCol == "-1") ? "circle" : "";
  const myTitle = (whatCol == "-1") ? "font-semibold text-sm wtitle" : "font-semibold text-sm";
  const myContent = (whatCol == "-1") ? "wcontent" : "";

  return (
    <div className="ref">
      <div className="reference">
        <div className={myCircle} style={{backgroundColor: data.metadata[variableTemplate].primary}}></div>
        <h6 className={myTitle}>{item.title}</h6>
        {item.startDate && (
        <h6 className="text-xs font-medium text-right wdate">
          {formatDateRange(
            {
              startDate: item.startDate,
              endDate: item.endDate,
              language,
            },
            t,
          )}
        </h6>
      )}
      </div>
      <div className={myContent} style={{borderColor: data.metadata[variableTemplate].primary}}>
      {item.link && (
          <a href={item.link} className="text-xs">
            {item.link}
          </a>
        )}
        {item.summary && (
          <div className="text-sm">{parse(item.summary)}</div>
        )}
      </div>
    </div>
  );
};

const ProjectsDra = () => {
  const { data, heading: Heading } = useContext(PageContext);
  const Icon = FaCopy;
  const variableTemplate = data.metadata.template;

  return safetyCheck(data.projects) ? (
    <div className={(visibleItems(data.projects.items)) ? "module projects" : "erase"}>
      <div className='flex items-center'>
        <Icon
          size="1.15em"
          className="mr-2"
          style={{ color: useSelector(`metadata.${variableTemplate}.primary`)}}
        />
        <h6 className="text-lg font-bold uppercase" style={{ color: data.metadata[variableTemplate].text}} >
        {data.projects.heading}
        </h6>
      </div>
      <div className="grid gap-2 contain">
        {data.projects.items.map(
          (x) =>
            isItemVisible(x) && (
              <ProjectItem
                key={x.id}
                item={x}
                language={data.metadata.language}
              />
            ),
        )}
      </div>
    </div>
  ) : null;
};

export default memo(ProjectsDra);

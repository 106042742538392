import React, { memo, useContext } from 'react';
import { isItemVisible, safetyCheck, visibleItems } from "../../../utils";
import PageContext from '../../../contexts/PageContext';

const LanguageItem = ({ id, name, fluency }) => {
  const {data} = useContext(PageContext);
  const variableTemplate = data.metadata.template;
  /*colG or colD*/
  const layout = data.metadata.layout[variableTemplate];
  const whatCol = layout[0].indexOf("languages");
  const lContain = (whatCol == "-1") ? "langContain2" : "langContain";

  return(
    <div key={id} className={lContain}>
      <div className="font-bold text-sm">{name}</div>
      <div className="text-xs">{fluency}</div>
    </div>
  )
};

const LanguagesRock = () => {
  const { data, heading: Heading } = useContext(PageContext);
  const variableTemplate = data.metadata.template;
  /*colG or colD*/
  const layout = data.metadata.layout[variableTemplate];
  const whatCol = layout[0].indexOf("languages");
  const myBlock = (whatCol == "-1") ? "grid grid-cols-3 gap-1 contain" : "grid";
  const myBorder = (whatCol == "-1") ? "cgUnderNone" : "cgUnderline";

  return safetyCheck(data.languages) ? (
    <div className={(visibleItems(data.languages.items)) ? "module languages" : "erase"}>
      <Heading parent='languages'>{visibleItems(data.languages.items) && data.languages.heading}</Heading>
      <div className={myBlock}>
        {data.languages.items.map((x) => isItemVisible(x) && LanguageItem(x))}
      </div>
      <div className={myBorder} style={{borderColor: data.metadata[variableTemplate].text}}></div>

    </div>
  ) : null;
};

export default memo(LanguagesRock);



import React from 'react';
import AwardsLine from './blocks/Awards/AwardsLine';
import ObjectiveLine from './blocks/Objective/ObjectiveLine';
import CertificationsLine from './blocks/Certifications/CertificationsLine';
import ContactLine from './blocks/Contact/ContactLine';
import EducationLine from './blocks/Education/EducationLine';
import HeadingLight from './blocks/Heading/HeadingLight';
import HobbiesLine from './blocks/Hobbies/HobbiesLine';
import LanguagesLine from './blocks/Languages/LanguagesLine';
import PageContext from '../contexts/PageContext';
import ProjectsLine from './blocks/Projects/ProjectsLine';
import ReferencesLine from './blocks/References/ReferencesLine';
import SkillsLine from './blocks/Skills/SkillsLine';
import WorkLine from './blocks/Work/WorkLine';
import { FaUserGraduate } from "react-icons/fa";


const Blocks = {
  objective: ObjectiveLine,
  work: WorkLine,
  education: EducationLine,
  projects: ProjectsLine,
  awards: AwardsLine,
  certifications: CertificationsLine,
  skills: SkillsLine,
  hobbies: HobbiesLine,
  languages: LanguagesLine,
  references: ReferencesLine,
  contact:ContactLine,
};

const Line = ({ data }) => {
  const layout = data.metadata.layout.line;
  const variableTemplate = data.metadata.template;
  /*const myBorder = data.metadata.line.primary;*/
  const Icon = FaUserGraduate;

  const Photo = () =>
    data.profile.photograph !== '' && (
      <img
        className="photo"
        style={{
          outlineColor : data.metadata.line.background,
        }}
        src={data.profile.photograph}
        alt={data.profile.firstName}
      />
    );


  const Ufall = () =>

    data.metadata.trainingProgram.logo3 !== '' ? (
      <div className="module ufall">
        <div className='heading flex items-center'>
            <Icon
              size="1.15em"
              className="mr-2"
              style={{ color: data.metadata[variableTemplate].primary}}
            />
            <h6 className= "font-bold uppercase text-lg" style={{lineHeight:"normal", borderColor:data.metadata[variableTemplate].primary}}>établissements</h6>
        </div>
        <div className="grid grid-cols-2 gap-2">
          <div className="UFA">
            <img src={data.metadata.trainingProgram.logo1} style={{width:"90%"}} />
          </div>
          <div className="UFA">
            <img src={data.metadata.trainingProgram.logo2} style={{width:"90%"}} />
          </div>
          <div className="UFA">
            <img src={data.metadata.trainingProgram.logo3} style={{width:"90%"}} />
          </div>
          <div className="UFA">
            <img src={data.metadata.trainingProgram.logoAFi24} style={{width:"90%"}} />
          </div>
        </div>
      </div>
    ): data.metadata.trainingProgram.logo2 !== '' ?(
      <div className="module ufall">
        <div className='heading flex items-center'>
          <Icon
            size="1.15em"
            className="mr-2"
            style={{ color: data.metadata[variableTemplate].primary}}
          />
          <h6 className= "font-bold uppercase text-lg" style={{lineHeight:"normal", borderColor:data.metadata[variableTemplate].primary}}>établissements</h6>
        </div>
      <div className="">
        <div className="UFA" style={{width:"45%",marginRight:"10px",display:"inline-block"}}>
          <img src={data.metadata.trainingProgram.logo1}/>
        </div>
        <div className="UFA" style={{width:"45%",display:"inline-block"}}>
          <img src={data.metadata.trainingProgram.logo2}/>
        </div>
        <div className="UFA" style={{width:"45%",margin:"0 auto" ,float:"left"}}>
          <img src={data.metadata.trainingProgram.logoAFi24}/>
        </div>
      </div>
      </div>
    ): (
      <div className="module ufall">
        <div className='heading flex items-center'>
          <Icon
            size="1.15em"
            className="mr-2"
            style={{ color: data.metadata[variableTemplate].primary}}
          />
          <h6 className= "font-bold uppercase text-lg" style={{lineHeight:"normal", borderColor:data.metadata[variableTemplate].primary}}>établissements</h6>
        </div>
        <div className="grid grid-cols-2 gap-2 module ufall">
          <div className="UFA">
            <img src={data.metadata.trainingProgram.logo1}/>
          </div>
          <div className="UFA">
            <img src={data.metadata.trainingProgram.logoAFi24}/>
          </div>
        </div>
      </div>
    );
  
  return (

  <PageContext.Provider value={{ data, heading: HeadingLight }}>
    <div
      id="pageTemplate" className="LineGab"
      style={{
        fontFamily: data.metadata.font,
        color: data.metadata.line.text,
        backgroundColor: data.metadata.line.background,
      }}
    >
      
      <div id="line" className="m-4">
        <div className="header">
          <div className="headerTop">
            <div className="grid grid-cols-12">
              <div className="nameContain col-span-9">
                <div className="name text-3xl" style={{color:  data.metadata.line.primary}} >
                  {data.profile.firstName}
                </div>
                <div className="name text-3xl font-bold uppercase" style={{color:  data.metadata.line.primary}} >
                  {data.profile.lastName}
                </div>
                <div className="sujet text-lg tracking-wide" style={{backgroundColor:data.metadata.line.primary, color:data.metadata.line.background}}>
                  {data.profile.subtitle}
                </div>
              </div>
            </div>
            <div className="formationTitle text-lg font-bold">{data.metadata.trainingProgram.nomformation}</div>
          </div>

          <div className="photoContain"><Photo /></div>
        </div>
        <div className="contactContain"><ContactLine /></div>
        <div className="main grid gap-5" >
          <Ufall />
        {layout[0] &&
          layout[0].map((x) => {
            const Component = Blocks[x];
            return Component && <Component key={x} />;
          })}
        </div>
      </div>
    </div>
  </PageContext.Provider>
  );
};


export default Line;

import React, { memo, useContext } from 'react';
import { formatDate, isItemVisible, safetyCheck, visibleItems } from "../../../utils";
import PageContext from '../../../contexts/PageContext';
import parse from "html-react-parser";

const AwardItem = ({ item, language }) => {

  const {data} = useContext(PageContext);
  const variableTemplate = data.metadata.template;

  return(
    <div>
      <div className="ref">
        <div className="reference">
          <h6 className="font-semibold text-sm">{item.title}</h6>
        </div>
        {item.date && (
            <h6 className="text-xs font-medium" style={{color: data.metadata[variableTemplate].primary}}>
              {formatDate({ date: item.date, language })}
            </h6>
          )}
        <div>
          <span className="text-xs" style={{width:"auto", display:"inline-block"}}>{item.awarder}</span>
          
          {item.summary.length > 7 && (
            <div className="mt-2 text-sm">{parse(item.summary)}</div>
          )}
        </div>
      </div>
    </div>
  );
};

const AwardsStel = () => {
  const { data, heading: Heading } = useContext(PageContext);
  const variableTemplate = data.metadata.template;
  const layout = data.metadata.layout[variableTemplate];
  const whatCol = layout[0].indexOf("awards");
  const myBlock = (whatCol == "-1") ? "grid gap-1" : "grid grid-cols-3 gap-2";

  return safetyCheck(data.awards) ? (
    <div className={(visibleItems(data.awards.items)) ? "module award" : "erase"}>
      <Heading parent='awards'>{data.awards.heading}</Heading>
      <div className={myBlock}>
        {data.awards.items.map(
          (x) =>
            isItemVisible(x) && (
              <AwardItem
                key={x.id}
                item={x}
                language={data.metadata.language}
              />
            ),
        )}
      </div>
    </div>
  ) : null;
};

export default memo(AwardsStel);

import { FaCaretRight } from 'react-icons/fa';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import React, { memo, useContext } from 'react';
import { hasAddress, isItemVisible, safetyCheck } from '../../../utils';
import BirthDateRock from '../BirthDate/BirthDateRock';
import Icons from '../Icons';
import PageContext from '../../../contexts/PageContext';

const ContactItem = ({ value, icon, link, type }) => {
  const { data } = useContext(PageContext);
  const Icon = get(Icons, icon && icon.toLowerCase(), FaCaretRight);
  const variableTemplate = data.metadata.template;
  const rootPath = data.metadata[variableTemplate];
  const myBlock =(type == "first") ? "containIcon" : "noContainIcon";
  const myColor =(type == "first") ? rootPath.primary : rootPath.text;

  return value ? (
    <div className="flex items-center text-xs">
      <div className={myBlock}
           style={{ backgroundColor: data.metadata[variableTemplate].text}}>
        <Icon
          size="11px"
          className="mr-2"
          style={{ color: myColor}}
        />
      </div>
      {link ? (
        <a href={link} target="_blank" rel="noopener noreferrer">
          <span className="break-all">{value}</span>
        </a>
      ) : (
        <span className="">{value}</span>
      )}
    </div>
  ) : null;
};

const ContactRock = () => {
  const { t } = useTranslation();
  const { data } = useContext(PageContext);
  const variableTemplate = data.metadata.template;

  return (
    <div className="module contact" style={{width:"105%"}}>
      <h6 className='mb-2 font-bold uppercase tracking-wide text-lg'>
        {data.profile.heading}
      </h6>
    <div className="text-xs grid gap-2">
      <ContactItem
        label={t('shared.forms.phone')}
        value={data.profile.phone}
        icon="phone"
        link={`tel:${data.profile.phone}`}
        type="first"
      />
      <ContactItem
        label={t('shared.forms.email')}
        value={data.profile.email}
        icon="email"
        link={`mailto:${data.profile.email}`}
        type="first"
      />
      {hasAddress(data.profile.address) && (
        <ContactItem
          label={t('shared.forms.address')}
          value={`${[
            data.profile.address.line1,
            data.profile.address.line2,
            data.profile.address.city,
          ]
            .filter(Boolean)
            .join(', ')} ${data.profile.address.pincode}`}
          icon="address"
          type="first"
        />
      )}
    </div>
    <div className="cgUnderline2" style={{borderColor: data.metadata[variableTemplate].text}}></div>
    <div className="text-xs grid" style={{marginTop:"5px"}}>
    <BirthDateRock />
    <ContactItem
      label={t('shared.forms.drivingLicence')}
      value={data.profile.drivingLicence}
      icon="driving"
      type="second"
    />
    <ContactItem
      label={t('shared.forms.mobility')}
      value={`Mobilité : ${data.profile.mobility}`}
      icon="mobility"
      type="second"
    />

    {safetyCheck(data.social) &&
      data.social.items.map(
        (x) =>
          isItemVisible(x) && (
            <ContactItem
              key={x.id}
              value={x.username}
              icon={x.network}
              link={x.url}
              type="second"
            />
          ),
      )}
    </div>


    <div className="cgUnderline" style={{borderColor: data.metadata[variableTemplate].text,paddingBottom: "10px",borderBottom: "1px solid "}}></div>
    </div>
  );
};

export default memo(ContactRock);

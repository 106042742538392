import React, { memo, useContext } from "react";
import { formatDate, isItemVisible, safetyCheck, visibleItems } from "../../../utils";
import PageContext from '../../../contexts/PageContext';
import parse from "html-react-parser";
import {FaPaste} from 'react-icons/fa';

const CertificationItem = ({ item, language }) => {
  const {data} = useContext(PageContext);
  const variableTemplate = data.metadata.template;
  const layout = data.metadata.layout[variableTemplate];


  return(
    <div>
      <div className="flex">
        <div className="flex flex-col text-left mr-2">
          <h6 className="font-bold text-sm">{item.title}</h6>
          <span className="text-xs">{item.issuer}</span>
        </div>
        {item.date && (
          <h6 className="text-xs" >
            {formatDate({ date: item.date, language })}
          </h6>
        )}
      </div>
      {item.summary && (
        <div className="mt-2 text-sm">{parse(item.summary)}</div>
      )}
    </div>
  );
};

const CertificationsLine = () => {
  const { data } = useContext(PageContext);
  const variableTemplate = data.metadata.template;
  const Icon = FaPaste;

  return safetyCheck(data.certifications) ? (
    <div className={(visibleItems(data.certifications.items)) ? "module certifications" : "erase"}>
      <div className='flex items-center'>
        <Icon
          size="1.15em"
          className="mr-2"
          style={{ color: data.metadata[variableTemplate].primary}}
        />
        <h6 className="text-lg font-bold uppercase" style={{ color: data.metadata[variableTemplate].text}} >
          {data.certifications.heading}
        </h6>
      </div>
      <div className="grid gap-1 wcontent" style={{borderColor: data.metadata[variableTemplate].primary}}>
        {data.certifications.items.map(
          (x) =>
            isItemVisible(x) && (
              <CertificationItem
                key={x.id}
                item={x}
                language={data.metadata.language}
              />

            ),
        )}
      </div>
    </div>
  ) : null;
};

export default memo(CertificationsLine);

import React, { memo, useContext } from 'react';
import { isItemVisible, safetyCheck, visibleItems } from "../../../utils";
import PageContext from '../../../contexts/PageContext';

const LanguageItem = ({ id, name, fluency }) => (
  <div key={id} className="">
    <h6 className="font-bold text-sm">{name}</h6>
    <span className="text-xs">{fluency}</span>
  </div>
);

const LanguagesA = () => {
  const { data, heading: Heading } = useContext(PageContext);

  return safetyCheck(data.languages) ? (
    <div className={(visibleItems(data.languages.items)) ? "module languages" : "erase"}>
      <Heading parent='languages'>{data.languages.heading}</Heading>
      <div className="grid grid-cols-2 gap-1">
        {data.languages.items.map((x) => isItemVisible(x) && LanguageItem(x))}
      </div>
    </div>
  ) : null;
};

export default memo(LanguagesA);

import React, { memo, useContext } from 'react';
import { formatDate, isItemVisible, safetyCheck, visibleItems } from "../../../utils";
import PageContext from '../../../contexts/PageContext';
import parse from "html-react-parser";

const CertificationItem = ({ item, language }) => {
  const {data} = useContext(PageContext);
  const variableTemplate = data.metadata.template;

  return(
  <div>
    <div className="flex flex-col refflex flex-col ref">
      <div className="reference">
        <h6 className="font-bold text-sm">{item.title}</h6>
      </div>
      {item.date && (
        <h6 className="text-xs font-medium" style={{width:"auto", display:"inline-block", float:"right",color: data.metadata[variableTemplate].primary}}>
          {formatDate({ date: item.date, language })}
        </h6>
      )}
      <div>
        <span className="text-xs" style={{width:"auto", display:"inline-block"}}>{item.issuer}</span>
    {item.summary.length > 7 && (
      <div className="mt-2 text-sm">{parse(item.summary)}</div>
    )}
    </div>
    </div>
  </div>
  );
};

const CertificationsStel = () => {
  const { data, heading: Heading } = useContext(PageContext);
  const variableTemplate = data.metadata.template;
  const layout = data.metadata.layout[variableTemplate];
  const whatCol = layout[0].indexOf("certifications");
  const myBlock = (whatCol == "-1") ? "grid grid-cols-2 gap-1" : "grid grid-cols-3 gap-1";

  return safetyCheck(data.certifications) ? (
    <div className={(visibleItems(data.certifications.items)) ? "module certifications" : "erase"}>
      <Heading parent='certifications'>{data.certifications.heading}</Heading>
      <div className={myBlock}>
        {data.certifications.items.map(
          (x) =>
            isItemVisible(x) && (
              <CertificationItem
                key={x.id}
                item={x}
                language={data.metadata.language}
              />
            ),
        )}
      </div>
    </div>
  ) : null;
};

export default memo(CertificationsStel);

import React, { memo, useContext } from 'react';
import { isItemVisible, safetyCheck, visibleItems } from "../../../utils";
import PageContext from '../../../contexts/PageContext';
import { useSelector } from '../../../contexts/ResumeContext';
import { FaFlag } from 'react-icons/fa';

const LanguageItem = ({ id, name, fluency }) => {
  const {data} = useContext(PageContext);
  const variableTemplate = data.metadata.template;
  /*colG or colD*/
  const layout = data.metadata.layout[variableTemplate];
  const whatCol = layout[0].indexOf("languages");
  const myBorder = (whatCol == "-1") ? "dcontent" : "";


 return (
 <div key={id} className={myBorder} style={{borderColor: data.metadata[variableTemplate].primary}} >
    <h6 className="font-semibold text-sm">{name}</h6>
    <span className="text-xs lang-lvl">{fluency}</span>
  </div>
  );
};

const LanguagesDra = () => {
  const { data, heading: Heading } = useContext(PageContext);
  const variableTemplate = data.metadata.template;
  const Icon = FaFlag;
   
  
  return safetyCheck(data.languages) ? (
    <div className={(visibleItems(data.languages.items)) ? "module languages" : "erase"}>
      <div className='flex items-center'>
      <Icon
        size="1.15em"
        className="mr-2"
        style={{ color: data.metadata[variableTemplate].primary}}
      />
      <div className="font-bold uppercase text-lg" style={{color: data.metadata[variableTemplate].text}}>
        {data.languages.heading}</div>
      </div>
      <div className="grid grid-cols-2 gap-1 contain">
        {data.languages.items.map((x) => isItemVisible(x) && LanguageItem(x))}
      </div>
    </div>
  ) : null;
};

export default memo(LanguagesDra);

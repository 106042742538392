import { useTranslation } from 'react-i18next';
import React, { memo, useContext } from 'react';
import PageContext from '../../../contexts/PageContext';
import { formatDateRange, isItemVisible, safetyCheck, visibleItems } from '../../../utils';
import {FaBookOpen} from 'react-icons/fa';
import { useSelector } from '../../../contexts/ResumeContext';
import parse from "html-react-parser";

const EducationItem = ({ item, language }) => {
  const { t } = useTranslation();
  const {data} = useContext(PageContext);
  const variableTemplate = data.metadata.template;
   /*colG or colD*/
  const layout = data.metadata.layout[variableTemplate];
   const whatCol = layout[0].indexOf("education");

  const myCircle = (whatCol == "-1") ? "circle" : "";
  const myTitle = (whatCol == "-1") ? "font-semibold text-sm wtitle" : "font-semibold text-sm";
  const myContent = (whatCol == "-1") ? "wcontent" : "";

  return (
    <div className="ref">
      <div className="reference">
        <div className="text-left mr-2">
        <div className={myCircle} style={{backgroundColor: data.metadata[variableTemplate].primary}}></div>
        <h6 className={myTitle}>{item.institution}</h6>
        {item.startDate && (
          <h6 className="text-xs font-medium mb-1 wdate">
          {formatDateRange(
            {
              startDate: item.startDate,
              endDate: item.endDate,
              language,
            },
            t,
          )}
          </h6>
          )}
          
        </div>
      </div>
      <div className={myContent} style={{borderColor: data.metadata[variableTemplate].primary}}>
        <span className="text-sm font-medium" style={{display:"block"}}>{item.gpa}</span>
        <span className="text-xs"><strong>{item.degree}</strong> {item.field}</span>
        {item.summary && (
          <div className="mt-2 text-sm">{parse(item.summary)}</div>
        )}
      </div>
    </div>
  );
};

const EducationDra = () => {
  const { data, heading: Heading } = useContext(PageContext);
  const Icon = FaBookOpen;
  const variableTemplate = data.metadata.template;

  return safetyCheck(data.education) ? (
    <div className={(visibleItems(data.education.items)) ? "module education" : "erase"}>
      <div className='flex items-center'>
        <Icon
          size="1.15em"
          className="mr-2"
          style={{ color: data.metadata[variableTemplate].primary}}
        />
        <h6 className="text-lg font-bold uppercase" style={{ color: data.metadata[variableTemplate].text}} >
        {data.education.heading}
        </h6>
      </div>
      <div className="grid gap-2 contain">
        {data.education.items.map(
          (x) =>
            isItemVisible(x) && (
              <EducationItem
                key={x.id}
                item={x}
                language={data.metadata.language}
              />
            ),
        )}
      </div>
    </div>
  ) : null;
};

export default memo(EducationDra);

import React, { memo, useContext } from 'react';
import PageContext from '../../../contexts/PageContext';
import { useSelector } from '../../../contexts/ResumeContext';

const HeadingPik = ({parent, children }) => {
  const { data } = useContext(PageContext);
  const variableTemplate = data.metadata.template;
  /*colG or colD*/
  //const register = useSelector(`metadata.register.${variableTemplate}`);
  const layout = data.metadata.layout[variableTemplate];
  const rootPath = data.metadata[variableTemplate];
  const whatCol = layout[0].indexOf(parent);
  //const myColor = (whatCol == "-1") ? useSelector(`metadata.${variableTemplate}.primary`) : useSelector(`metadata.${variableTemplate}.background`);
  const myColor = (whatCol == "-1") ? rootPath.primary : rootPath.background;

  //const myBorderColor = (whatCol == "-1") ? useSelector(`metadata.${variableTemplate}.primary`) : useSelector(`metadata.${variableTemplate}.primary`);
  const myBorderColor = (whatCol == "-1") ? rootPath.primary : rootPath.primary;

  const myClass = (whatCol == "-1") ? 'mb-2 border-b-2 font-bold uppercase tracking-wide text-lg' : 'font-bold uppercase tracking-wide text-lg';

  return (
    <h6
      className= {myClass}
      style={{
        color: myColor, borderColor: myBorderColor,
      }}
    >
      {children}
    </h6>
  );
};

export default memo(HeadingPik);

import { useTranslation } from 'react-i18next';
import React, { memo, useContext } from 'react';
import PageContext from '../../../contexts/PageContext';
import { formatDateRange, isItemVisible, safetyCheck, visibleItems } from "../../../utils";
import parse from "html-react-parser";

const WorkItem = ({ item, language }) => {
  const { t } = useTranslation();
  const {data} = useContext(PageContext);
  const variableTemplate = data.metadata.template;

  return (
    <div className="ref">
      <div className="flex justify-between reference"> 
        <h6 className='text-sm font-bold'>{item.company}</h6>
        {item.startDate && (
          <h6 className="text-xs text-right wdate" style={{color: data.metadata[variableTemplate].primary}}>
            {formatDateRange(
              {
                startDate: item.startDate,
                endDate: item.endDate,
                language,
              },
              t,
            )}
          </h6>
        )}
      </div>
      <div>
        <span className="text-xs">{item.position}</span>
        {item.summary.length > 7 && (
          <div className="mt-1 text-sm">{parse(item.summary)}</div>
        )}
        <div className="underline"></div>
        </div>
        
      </div>
  );
};

const WorkStel = () => {
  const { data, heading: Heading } = useContext(PageContext);

  return safetyCheck(data.work) ? (
    <div className={(visibleItems(data.work.items)) ? "module work" : "erase"}>
      <Heading parent='languages'>{data.work.heading}</Heading>
      <div className="grid gap-2">
        {data.work.items.map(
          (x) =>
            isItemVisible(x) && (
              <WorkItem key={x.id} item={x} language={data.metadata.language} />
            ),
        )}
      </div>
    </div>
  ) : null;
};

export default memo(WorkStel);

import React, { memo, useContext } from 'react';
import PageContext from '../../../contexts/PageContext';
import { useSelector } from '../../../contexts/ResumeContext';

const HeadingA = ({ children }) => {
  const { data } = useContext(PageContext);
  const variableTemplate = data.metadata.template;

  return (
    <h6
      className="text-xs font-bold uppercase mb-1"
      style={{ color: data.metadata[variableTemplate].primary }}
    >
      {children}
    </h6>
  );
};

export default memo(HeadingA);

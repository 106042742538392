import React from 'react';
import AwardsCast from './blocks/Awards/AwardsCast';
import CertificationsCast from './blocks/Certifications/CertificationsCast';
import ContactPik from './blocks/Contact/ContactPik';
import EducationA from './blocks/Education/EducationA';
import HeadingCast from './blocks/Heading/HeadingCast';
import HobbiesA from './blocks/Hobbies/HobbiesA';
import LanguagesPik from './blocks/Languages/LanguagesPik';
import ObjectivePik from './blocks/Objective/ObjectivePik';
import PageContext from '../contexts/PageContext';
import ProjectsA from './blocks/Projects/ProjectsA';
import ReferencesPik from './blocks/References/ReferencesPik';
import SkillsCast from './blocks/Skills/SkillsCast';
import WorkPik from './blocks/Work/WorkPik';

const Blocks = {
  objective: ObjectivePik,
  work: WorkPik,
  education: EducationA,
  projects: ProjectsA,
  awards: AwardsCast,
  certifications: CertificationsCast,
  skills: SkillsCast,
  hobbies: HobbiesA,
  languages: LanguagesPik,
  references: ReferencesPik,
};

const Castform = ({ data }) => {
  const layout = data.metadata.layout.castform;


  const Photo = () =>
    data.profile.photograph !== '' && (
      <img
        className="rounded-full photo"
        style={{
          borderWidth: 6,
          borderColor: data.metadata.castform.background,
        }}
        src={data.profile.photograph}
        alt={data.profile.firstName}
      />
    );

  const Profile = () => (
    <div>
      <div className="flex column relative name">
        <h1 className="text-2xl leading-tight">
          {data.profile.firstName}
        </h1>
      </div>
      <div className="flex column relative name">
        <h1 className="text-2xl font-bold leading-tight uppercase">
          {data.profile.lastName}
        </h1>
      </div>
    </div>

  );

  const CastUfall = () =>
    data.metadata.trainingProgram.logo3 !== '' ? (
      <div className="containUFA" style={{justifyContent:'space-between'}}>
        <div className="UFA text-xs" style={{ width:"25%"}}>
          <img src={data.metadata.trainingProgram.logo1}  style={{width:"90%"}}/>
        </div>
        <div className="UFA text-xs" style={{ width:"25%"}}>
          <img src={data.metadata.trainingProgram.logo2}  style={{width:"90%"}}/>
        </div>
        <div className="UFA text-xs" style={{ width:"25%"}}>
          <img src={data.metadata.trainingProgram.logo3}  style={{width:"90%"}}/>
        </div>
        <div className="UFA text-xs" style={{ width:"25%"}}>
          <img src={data.metadata.trainingProgram.logoAFi24}  style={{width:"90%"}}/>
        </div>
      </div>
    ): data.metadata.trainingProgram.logo2 !== '' ?(
      <div className="containUFA" style={{justifyContent:'space-between'}}>
        <div className="UFA text-xs">
          <img src={data.metadata.trainingProgram.logo1} />
        </div>
        <div className="UFA text-xs">
          <img src={data.metadata.trainingProgram.logo2} />
        </div>
        <div className="UFA text-xs">
          <img src={data.metadata.trainingProgram.logoAFi24} />
        </div>
      </div>
    ):(
      <div className="containUFA" style={{justifyContent:'space-evenly'}}>
        <div className="UFA text-xs" style={{ border:"none", float:"left"}}>
          <img src={data.metadata.trainingProgram.logo1} />
        </div>
        <div className="UFA text-xs" style={{ border:"none", float:"right"}}>
          <img src={data.metadata.trainingProgram.logoAFi24} />
        </div>
      </div>
    );

  return (
    <PageContext.Provider value={{ data, heading: HeadingCast }}>
      <div
        id="page" className="castformGab"
        style={{
          fontFamily: data.metadata.font,
          fontSize: data.metadata.fontSize,
          backgroundColor: data.metadata.castform.background,
        }}
      >
        <div id="castform" className="grid grid-cols-12">
          <div
            className="col-span-4 p-5 cg"
            style={{
              color: data.metadata.castform.background,
              backgroundColor: data.metadata.castform.primary
            }}
          >
            <div className="grid gap-4">
              <Photo />
              <Profile />

              <div>
                <ContactPik />
              </div>

              {layout[0] &&
                layout[0].map((x) => {
                  const Component = Blocks[x];
                  return Component && <Component key={x} />;
                })}
            </div>
          </div>
          <div className="col-span-8 p-5 cd"
               style={{
                 color: data.metadata.castform.text,
                 backgroundColor: data.metadata.castform.background
               }}>
            <div className="text-sm font-medium tracking-wide sujet">
              {data.profile.subtitle}
            </div>
            <div className="font-bold text-xl formation" style={{color: data.metadata.castform.primary}} >
              {data.metadata.trainingProgram.nomformation}
            </div>
            <CastUfall />
            <div className="grid gap-4">
              {layout[1] &&
                layout[1].map((x) => {
                  const Component = Blocks[x];
                  return Component && <Component key={x} />;
                })}
            </div>
          </div>
        </div>
      </div>
    </PageContext.Provider>
  );
};

export default Castform;

import React from 'react';
import AwardsStel from './blocks/Awards/AwardsStel';
import ObjectiveA from './blocks/Objective/ObjectiveA';
import CertificationsStel from './blocks/Certifications/CertificationsStel';
import ContactStel from './blocks/Contact/ContactStel';
import EducationStel from './blocks/Education/EducationStel';
import HeadingStel from './blocks/Heading/HeadingStel';
import HobbiesA from './blocks/Hobbies/HobbiesA';
import LanguagesA from './blocks/Languages/LanguagesA';
import PageContext from '../contexts/PageContext';
import ProjectsStel from './blocks/Projects/ProjectsStel';
import ReferencesStel from './blocks/References/ReferencesStel';
import SkillsP from './blocks/Skills/SkillsP';
import WorkStel from './blocks/Work/WorkStel';
import {useSelector} from '../contexts/ResumeContext';
import BirthDatePik from './blocks/BirthDate/BirthDatePik';
import Icons from './blocks/Icons';
import { useTranslation } from 'react-i18next';
import { FaCaretRight } from 'react-icons/fa';
import { get } from 'lodash';

const Blocks = {
  work: WorkStel,
  objective: ObjectiveA,
  education: EducationStel,
  projects: ProjectsStel,
  awards: AwardsStel,
  certifications: CertificationsStel,
  skills: SkillsP,
  hobbies: HobbiesA,
  languages: LanguagesA,
  references: ReferencesStel,
};



const Stellar = ({ data }) => {
  const layout = data.metadata.layout.stellar;  
  const variableTemplate = data.metadata.template;
 /* const { r, g, b } = hexToRgb(useSelector(`metadata.${variableTemplate}.text`)) || {};*/
  const { t } = useTranslation();

  const ContactItem = ({ value, icon, link }) => {
    const Icon = get(Icons, icon && icon.toLowerCase(), FaCaretRight);
    const variableTemplate = data.metadata.template;
  
    return value ? (
      <div className="text-sm flex items-center">
        <Icon
          size="12px"
          className="mr-2"
          style={{ color: useSelector(`metadata.${variableTemplate}.background`)}}
          /*style={{ color: `data.metadata.${variableTemplate}.background`}}*/
        />
        {link ? (
          <a href={link} target="_blank" rel="noopener noreferrer">
            <span className="break-all">{value}</span>
          </a>
        ) : (
          <span className="font-bold break-all">{value}</span>
        )}
      </div>
    ) : null;
  };

  const Ufall = () =>
  data.metadata.trainingProgram.logo3 !== '' ? (
    <div className="grid grid-cols-2 gap-2 ufall">
      <div className="UFA">
        <img src={data.metadata.trainingProgram.logo1} />
      </div>
      <div className="UFA">
        <img src={data.metadata.trainingProgram.logo2}/>
      </div>
      <div className="UFA">
        <img src={data.metadata.trainingProgram.logo3}/>
      </div>
      <div className="UFA">
        <img src={data.metadata.trainingProgram.logoAFi24}/>
      </div>
    </div>
  ): data.metadata.trainingProgram.logo2 !== '' ?(
    <div className="">
      <div className="UFA" style={{width:"48%",marginRight:"10px",display:"inline-block"}}>
        <img src={data.metadata.trainingProgram.logo1}/>
      </div>
      <div className="UFA" style={{width:"48%",display:"inline-block"}}>
        <img src={data.metadata.trainingProgram.logo2}/>
      </div>
      <div className="UFA" style={{width:"49%",margin:"0 auto"}}>
        <img src={data.metadata.trainingProgram.logoAFi24}/>
      </div>
    </div>
  ): (
    <div className="grid grid-cols-2 gap-2 ufall">
      <div className="UFA">
        <img src={data.metadata.trainingProgram.logo1}/>
      </div>
      <div className="UFA">
        <img src={data.metadata.trainingProgram.logoAFi24}/>
      </div>
    </div>
  );

  const gradColor1 = useSelector(`metadata.${variableTemplate}.background`);

  //const gradColor2 = useSelector(`metadata.stellar.primary`);
  const gradColor2 = data.metadata.stellar.primary;

  const myGradient = `linear-gradient(0deg,${gradColor1} 0%,${gradColor2} 25%)`;

  return (
  <PageContext.Provider value={{ data, heading: HeadingStel }}>
    <div
      id="page"
      className="stellarGab"
        style={{
          fontFamily: data.metadata.font,
          color: useSelector(`metadata.${variableTemplate}.text`),
          backgroundColor: useSelector(`metadata.${variableTemplate}.background`),
        }}
      >
      <div id="stellar">
          <div className="header" style={{ background: myGradient}}>
            <div className='grid grid-cols-12 items-start contain'>
            <div className="col-span-9 nom">
              
              {data.profile.photograph && (
                <img
                  className="rounded object-cover mr-4 photo"
                  src={data.profile.photograph}
                  alt={data.profile.firstName}
                  style={{alignSelf:"center", borderColor: useSelector(`metadata.${variableTemplate}.background`)}}
                />
              )}
            
                <div className="contain-name" style={{
                  color: useSelector(`metadata.${variableTemplate}.background`)
                }}>
                <div className="flex column relative firstname">
                  <h1 className="text-3xl leading-tight">
                    {data.profile.firstName}
                  </h1>
                </div>
                <div className="flex column relative name">
                  <h1 className="text-3xl font-bold  leading-tight uppercase">
                    {data.profile.lastName}
                  </h1>
                </div>
                <div className="font-bold text-xl formation">
                  {data.metadata.trainingProgram.nomformation}
                </div>
                <div className="text-sm font-bold tracking-wide sujet">
                  {data.profile.subtitle}
                </div>
              </div>
            </div>
            <div className="col-span-3 profil grid gap-1" style={{color:useSelector(`metadata.${variableTemplate}.background`)}}>
              <BirthDatePik />
              <ContactItem
                label={t('shared.forms.phone')}
                value={data.profile.phone}
                icon="phone"
                link={`tel:${data.profile.phone}`}
              />
              <ContactItem
                label={t('shared.forms.email')}
                value={data.profile.email}
                icon="email"
                link={`mailto:${data.profile.email}`}
              />
            </div>
            </div>
          </div>
          <div className="grid grid-cols-12">
            <div className="col-span-8 cg">
              <div className="grid gap-4" style={{padding:"15px 20px 0 15px"}}>
                
                {layout[0] && layout[0].map((x) => {
                  const Component = Blocks[x];
                  return Component && <Component key={x} />;
                })}
              </div>
            </div>
            <div className="col-span-4 cd">
              <div className="grid gap-2">
                <Ufall/> 
                <ContactStel />
                {layout[1] &&
                layout[1].map((x) => {
                  const Component = Blocks[x];
                  return Component && <Component key={x} />;
                })}
              </div>
            </div>
          </div>
      </div>
    </div>
    <div style={{backgroundColor:'#808080', position:'absolute', width:'210mm', marginTop:'5px', textAlign:'center'}}><span>↑ Un bon CV se pr&eacute;sente g&eacute;n&eacute;ralement sur une seule page, veillez à ne pas d&eacute;passer cette limite représentant une feuille A4 ↑</span></div>
  </PageContext.Provider>
  );
};


export default Stellar;

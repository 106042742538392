import React, { memo, useContext } from 'react';
import { isItemVisible, safetyCheck, visibleItems } from "../../../utils";
import PageContext from '../../../contexts/PageContext';

const LanguageItem = ({ id, name, fluency }) => {
  return(
    <div key={id} className="langContain">
      <div className="font-bold text-sm">{name}</div>
      <div className="text-xs">{fluency}</div>
    </div>
  )
};

const LanguagesLight = () => {
  const { data, heading: Heading } = useContext(PageContext);
  return safetyCheck(data.languages) ? (
    <div className={(visibleItems(data.languages.items)) ? "module languages" : "erase"}>
      <Heading parent='languages'>{visibleItems(data.languages.items) && data.languages.heading}</Heading>
      <div className="grid">
        {data.languages.items.map((x) => isItemVisible(x) && LanguageItem(x))}
      </div>
    </div>
  ) : null;
};

export default memo(LanguagesLight);



import React, { memo, useContext } from 'react';
import { formatDate, isItemVisible, safetyCheck, visibleItems } from "../../../utils";
import PageContext from '../../../contexts/PageContext';
import parse from "html-react-parser";
import {FaMedal} from 'react-icons/fa';

const AwardItem = ({ item, language }) => {
  return(
    <div>
      <div className="flex">
        <div className="flex flex-col text-left mr-2">
          <h6 className="font-bold text-sm">{item.title}</h6>
          <span className="text-xs">{item.awarder}</span>
        </div>
        {item.date && (
          <h6 className="text-xs">
            {formatDate({ date: item.date, language })}
          </h6>
        )}
      </div>
      {item.summary && (
        <div className="mt-2 text-sm">{parse(item.summary)}</div>
      )}
    </div>
  );
    };

const AwardsLine = () => {
  const { data } = useContext(PageContext);
  const variableTemplate = data.metadata.template;
  const Icon = FaMedal;

  return safetyCheck(data.awards) ? (

    <div className={(visibleItems(data.awards.items)) ? "module award" : "erase"}>
      <div className='flex items-center'>
        <Icon
          size="1.15em"
          className="mr-2"
          style={{ color: data.metadata[variableTemplate].primary}}
        />
        <h6 className="text-lg font-bold uppercase" style={{ color: data.metadata[variableTemplate].text}} >
          {data.awards.heading}
        </h6>
      </div>
      <div className="grid gap-1 wcontent" style={{borderColor: data.metadata[variableTemplate].primary}}>
        {data.awards.items.map(
          (x) =>
            isItemVisible(x) && (
              <AwardItem
                key={x.id}
                item={x}
                language={data.metadata.language}
              />
            ),
        )}
      </div>
    </div>
  ) : null;
};

export default memo(AwardsLine);

import React, { memo, useContext } from 'react';
import { formatDate, isItemVisible, safetyCheck, visibleItems} from '../../../utils';
import PageContext from '../../../contexts/PageContext';
import {FaMedal} from 'react-icons/fa';
import { useSelector } from '../../../contexts/ResumeContext';
import parse from "html-react-parser";


var myBlock

const AwardItem = ({ item, language }) => {
  
  const {data} = useContext(PageContext);
  const variableTemplate = data.metadata.template;
    /*colG or colD*/
  const layout = data.metadata.layout[variableTemplate];
  const whatCol = layout[0].indexOf("awards");

  const myCircle = (whatCol == "-1") ? "circle" : "";
  const myTitle = (whatCol == "-1") ? "font-semibold text-sm wtitle" : "font-semibold text-sm";
  const myContent = (whatCol == "-1") ? "wcontent" : "";
  const titleStyle = (whatCol == "-1") ? "94%" : "100%";
  myBlock = (whatCol == "-1") ? "grid grid-cols-3 gap-2 contain" : "grid gap-2 contain";

    return (
      <div className=" ref">
        <div className="reference">
          <div className={myCircle} style={{backgroundColor: data.metadata[variableTemplate].primary}}></div>
          <h6 className={myTitle} style={{width:titleStyle, verticalAlign:"top"}}>{item.title}</h6>   
        </div>
        <div className={myContent} style={{borderColor: data.metadata[variableTemplate].primary}}>
          <span className="text-xs">{item.awarder}</span>
            {item.date && (
              <h6 className="text-xs font-medium text-left">
                {formatDate({ date: item.date, language })}
              </h6>
            )}
          {item.summary && (
            <div className="mt-2 text-sm">{parse(item.summary)}</div>
          )}
        </div>
      </div>
    );
  };

  const AwardsDra = () => {
    const { data, heading: Heading } = useContext(PageContext);
    const Icon = FaMedal;
    const variableTemplate = data.metadata.template;

    return safetyCheck(data.awards) ? (
      <div className={(visibleItems(data.awards.items)) ? "module awards" : "erase"}>
      <div className='flex items-center'>
        <Icon
          size="1.15em"
          className="mr-2"
          style={{ color: data.metadata[variableTemplate].primary}}
        />
        <h6 className="text-lg font-bold uppercase" style={{ color: data.metadata[variableTemplate].text}} >
        {data.awards.heading}
        </h6>
      </div>
      <div className={myBlock}>
        {data.awards.items.map(
          (x) =>
            isItemVisible(x) && (
              <AwardItem
                key={x.id}
                item={x}
                language={data.metadata.language}
              />
            ),
        )}
      </div>
    </div>
  ) : null;
};

export default memo(AwardsDra);

import React, { memo, useContext } from 'react';
import { headingStyle, isItemVisible, safetyCheck, visibleItems } from "../../../utils";
import PageContext from '../../../contexts/PageContext';
import { ProgressBar } from "@progress/kendo-react-progressbars";

const SkillItem = ({ id, name, level }) => {
  const {data} = useContext(PageContext);
  const variableTemplate = data.metadata.template;
  return (
    <div key={id} className="progressAll">
      <h6 className="font-bold text-xs">{name}</h6>
      <ProgressBar min={0} max={5} value={level}  labelVisible={false} style={{backgroundColor: data.metadata[variableTemplate].primary}}/>
    </div>
  );
};

const SkillsP = () => {
  const { data, heading: Heading } = useContext(PageContext);


  return safetyCheck(data.skills) ? (
    <div className={(visibleItems(data.skills.items)) ? "module skills" : "erase"}>
      <Heading parent='skills'>{visibleItems(data.skills.items) && data.skills.heading}</Heading>
      <div className="grid gap-1">
        {data.skills.items.map((x) => isItemVisible(x) && SkillItem(x))}
      </div>
      <div style={headingStyle(data.skills.items)} className="cgUnderline"></div>
    </div>
  ) : null;
};

export default memo(SkillsP);
import React from 'react';
import AwardsRock from './blocks/Awards/AwardsRock';
import ObjectiveRock from './blocks/Objective/ObjectiveRock';
import CertificationsRock from './blocks/Certifications/CertificationsRock';
import ContactLight from './blocks/Contact/ContactLight';
import EducationRock from './blocks/Education/EducationRock';
import HeadingLight from './blocks/Heading/HeadingLight';
import HobbiesRock from './blocks/Hobbies/HobbiesRock';
import LanguagesLight from './blocks/Languages/LanguagesLight';
import PageContext from '../contexts/PageContext';
import ProjectsLight from './blocks/Projects/ProjectsLight';
import ReferencesRock from './blocks/References/ReferencesRock';
import SkillsLight from './blocks/Skills/SkillsLight';
import WorkRock from './blocks/Work/WorkRock';


const Blocks = {
  objective: ObjectiveRock,
  work: WorkRock,
  education: EducationRock,
  projects: ProjectsLight,
  awards: AwardsRock,
  certifications: CertificationsRock,
  skills: SkillsLight,
  hobbies: HobbiesRock,
  languages: LanguagesLight,
  references: ReferencesRock,
  contact:ContactLight,
};

const Light = ({ data }) => {
  const layout = data.metadata.layout.light;
  const variableTemplate = data.metadata.template;

  const Photo = () =>
    data.profile.photograph !== '' && (
      <img
        className="photo"
        style={{
          outlineColor : data.metadata.light.background,
        }}
        src={data.profile.photograph}
        alt={data.profile.firstName}
      />
    );


  const Ufall = () =>
    data.metadata.trainingProgram.logo3 !== '' ? (
      <div className="module ufall">
        <div className='heading'>
          <h6 className= "mb-2 border-b-2 font-bold uppercase tracking-wide text-lg" style={{lineHeight:"normal", borderColor:data.metadata[variableTemplate].primary}}>établissements</h6>
        </div>
        <div className="grid grid-cols-2 gap-2">
          <div className="UFA">
            <img src={data.metadata.trainingProgram.logo1} style={{width:"90%"}} />
          </div>
          <div className="UFA">
            <img src={data.metadata.trainingProgram.logo2} style={{width:"90%"}} />
          </div>
          <div className="UFA">
            <img src={data.metadata.trainingProgram.logo3} style={{width:"90%"}} />
          </div>
          <div className="UFA">
            <img src={data.metadata.trainingProgram.logoAFi24} style={{width:"90%"}} />
          </div>
        </div>
      </div>
    ): data.metadata.trainingProgram.logo2 !== '' ?(
      <div className="module ufall">
        <div className='heading'>
          <h6 className= "mb-2 border-b-2 font-bold uppercase tracking-wide text-lg" style={{lineHeight:"normal", borderColor:data.metadata[variableTemplate].primary}}>établissements</h6>
        </div>
      <div className="">
        <div className="UFA" style={{width:"45%",marginRight:"10px",display:"inline-block"}}>
          <img src={data.metadata.trainingProgram.logo1}/>
        </div>
        <div className="UFA" style={{width:"45%",display:"inline-block"}}>
          <img src={data.metadata.trainingProgram.logo2}/>
        </div>
        <div className="UFA" style={{width:"45%",margin:"0 auto" ,float:"left"}}>
          <img src={data.metadata.trainingProgram.logoAFi24}/>
        </div>
      </div>
      </div>
    ): (
      <div className="module ufall">
        <div className='heading'>
          <h6 className= "mb-2 border-b-2 font-bold uppercase tracking-wide text-lg" style={{lineHeight:"normal", borderColor:data.metadata[variableTemplate].primary}}>établissements</h6>
        </div>
        <div className="grid grid-cols-2 gap-2 module ufall">
          <div className="UFA">
            <img src={data.metadata.trainingProgram.logo1}/>
          </div>
          <div className="UFA">
            <img src={data.metadata.trainingProgram.logoAFi24}/>
          </div>
        </div>
      </div>
    );
  
  return (

  <PageContext.Provider value={{ data, heading: HeadingLight }}>
    <div
      id="pageTemplate" className="lightGab"
      style={{
        fontFamily: data.metadata.font,
        color: data.metadata.light.text,
        backgroundColor: data.metadata.light.background,
      }}
    >
      
      <div id="light" className="m-4">
        <div className="header">
          <div className="photoContain"><Photo /></div>
          <div className="headerTop">
            <div className="grid grid-cols-12">
              <div className="nameContain col-span-9">
                <div className="name text-3xl" style={{color:  data.metadata.light.primary}} >
                  {data.profile.firstName}
                </div>
                <div className="name text-3xl font-bold uppercase" style={{color:  data.metadata.light.primary}} >
                  {data.profile.lastName}
                </div>
                <div className="sujet text-sm font-medium tracking-wide" style={{backgroundColor:data.metadata.light.primary, color:data.metadata.light.background}}>
                  {data.profile.subtitle}
                </div>
              </div>
              <div className="permisContain col-span-3"><ContactLight pos="permis"/></div>
            </div>
            <div className="formationTitle text-lg font-bold">{data.metadata.trainingProgram.nomformation}</div>
          </div>
        </div>
        <div className="contactContain"><ContactLight pos="contact"/></div>
        <div className="main grid gap-5" >
          <Ufall />
        {layout[0] &&
          layout[0].map((x) => {
            const Component = Blocks[x];
            return Component && <Component key={x} />;
          })}
        </div>
      </div>
    </div>
  </PageContext.Provider>
  );
};


export default Light;

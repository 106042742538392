import React from 'react';
import AwardsWind from './blocks/Awards/AwardsWind';
import ObjectiveWind from './blocks/Objective/ObjectiveWind';
import CertificationsWind from './blocks/Certifications/CertificationsWind';
import ContactWind from './blocks/Contact/ContactWind';
import EducationWind from './blocks/Education/EducationWind';
import HeadingWind from './blocks/Heading/HeadingWind';
import HobbiesWind from './blocks/Hobbies/HobbiesWind';
import LanguagesWind from './blocks/Languages/LanguagesWind';
import PageContext from '../contexts/PageContext';
import ProjectsWind from './blocks/Projects/ProjectsWind';
import ReferencesWind from './blocks/References/ReferencesWind';
import SkillsWind from './blocks/Skills/SkillsWind';
import WorkWind from './blocks/Work/WorkWind';
import {useSelector} from '../contexts/ResumeContext';


const Blocks = {
  work: WorkWind,
  objective: ObjectiveWind,
  education: EducationWind,
  projects: ProjectsWind,
  awards: AwardsWind,
  certifications: CertificationsWind,
  skills: SkillsWind,
  hobbies: HobbiesWind,
  languages: LanguagesWind,
  references: ReferencesWind,
};

const Wind = ({ data }) => {
  const layout = data.metadata.layout.wind;
  const variableTemplate = data.metadata.template;

  const Ufall = () =>
  data.metadata.trainingProgram.logo3 !== '' ? (
    <div className="grid grid-cols-2 gap-2 ufall">
      <div className="UFA">
        <img src={data.metadata.trainingProgram.logo1} style={{width:"90%"}} />
      </div>
      <div className="UFA">
        <img src={data.metadata.trainingProgram.logo2} style={{width:"90%"}} />
      </div>
      <div className="UFA">
        <img src={data.metadata.trainingProgram.logo3} style={{width:"90%"}} />
      </div>
      <div className="UFA">
        <img src={data.metadata.trainingProgram.logoAFi24} style={{width:"90%"}} />
      </div>
    </div>
  ): data.metadata.trainingProgram.logo2 !== '' ?(
    <div className="">
      <div className="UFA" style={{width:"48%",marginRight:"10px",display:"inline-block"}}>
        <img src={data.metadata.trainingProgram.logo1}/>
      </div>
      <div className="UFA" style={{width:"48%",display:"inline-block"}}>
        <img src={data.metadata.trainingProgram.logo2}/>
      </div>
      <div className="UFA" style={{width:"49%",margin:"0 auto"}}>
        <img src={data.metadata.trainingProgram.logoAFi24}/>
      </div>
    </div>
  ): (
    <div className="grid grid-cols-2 gap-2 ufall">
      <div className="UFA">
        <img src={data.metadata.trainingProgram.logo1}/>
      </div>
      <div className="UFA">
        <img src={data.metadata.trainingProgram.logoAFi24}/>
      </div>
    </div>
  );

  return (
  <PageContext.Provider value={{ data, heading: HeadingWind }}>
    <div
      id="page"
      className="p-8 rounded windGab"
        style={{
          fontFamily: data.metadata.font,
          color: useSelector(`metadata.${variableTemplate}.text`),
          backgroundColor: useSelector(`metadata.${variableTemplate}.background`),
        }}
      >
        <div id="wind">
          <div className="grid grid-cols-12 items-start headWind">
            <div className="col-span-9 nom">
              <div className="contain-name">
                <div className="flex column relative firstname">
                  <h1 className="text-3xl leading-tight">
                    {data.profile.firstName}
                  </h1>
                </div>
                <div className="flex column relative name">
                  <h1 className="text-3xl font-bold leading-tight uppercase">
                    {data.profile.lastName}
                  </h1>
                </div>
                <div className="font-bold text-xl formation">
                  {data.metadata.trainingProgram.nomformation}
                </div>
              </div>
              <div className={((data.profile.subtitle).length > 7) ? "sujetContain" : "eraseWind"}>
                <div className="text-sm font-bold tracking-wide sujet" style={{backgroundColor: useSelector(`metadata.${variableTemplate}.primary`), color: useSelector(`metadata.${variableTemplate}.background`)}}>
                  {data.profile.subtitle}
                </div>
                <div className="sujetGraf" style={{backgroundColor: useSelector(`metadata.${variableTemplate}.text`)}}>&nbsp;</div>
              </div>
            </div>
            <div className="col-span-3">
              {data.profile.photograph && (
                <img
                  className="rounded object-cover mr-4 photo"
                  src={data.profile.photograph}
                  alt={data.profile.firstName}
                />
              )}
            </div>
          </div>
        <div className="grid grid-cols-12 mainWind">
          <div className="col-span-4 cg">
            <div className="grid gap-5" style={{height:"100%"}}>
              <div className='etablissement'>
                <div className='heading'>
                  <h6 className="text-lg font-bold uppercase tracking-wide title" style={{color: data.metadata[variableTemplate].text}}>établissements</h6>
                </div>
                <Ufall/>
              </div>
              <ContactWind />
              {layout[0] &&
                layout[0].map((x) => {
                  const Component = Blocks[x];
                  return Component && <Component key={x} />;
                })}

            </div>

          </div>
          <div className="col-span-8 cd">
            <div className="grid gap-5" style={{height:"100%"}}>
              {layout[1] && layout[1].map((x) => {
                const Component = Blocks[x];
                return Component && <Component key={x} />;
              })}
            </div>
          </div>

          
        </div>
      </div>
    </div>
  </PageContext.Provider>
  );
};


export default Wind;

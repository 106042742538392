import React, { memo, useContext } from "react";
import {  isItemVisible, safetyCheck, visibleItems } from "../../../utils";
import PageContext from '../../../contexts/PageContext';
import parse from "html-react-parser";

const ReferenceItem = ({ id, name, position, phone, email, summary }) => (
  <div key={id} className="flex flex-col">
    <h6 className="font-bold text-sm">{name}</h6>
    <span className="text-xs">{position}</span>
    <span className="text-xs">{phone}</span>
    <span className="text-xs">{email}</span>
    {summary && (
      <div className="mt-2 text-sm">{parse(summary)}</div>
    )}
  </div>
);


const ReferencesPik = () => {
  const { data, heading: HeadingPik} = useContext(PageContext);
  const layout = data.metadata.layout.wind;
   const whatCol = layout[0].indexOf("references");
   const myBlock = (whatCol == "-1") ? "grid grid-cols-2 gap-2" : "grid gap-2";

  return safetyCheck(data.references) ? (
    <div className={(visibleItems(data.references.items)) ? "module references" : "erase"}>
      <HeadingPik parent='references'>{visibleItems(data.references.items) && data.references.heading}</HeadingPik>
      <div className={myBlock}>
        {data.references.items.map((x) => isItemVisible(x) && ReferenceItem(x))}
      </div>
    </div>
  ) : null;
};

export default memo(ReferencesPik);

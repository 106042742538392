import React from 'react';
import AwardsPik from './blocks/Awards/AwardsPik';
import ObjectivePik from './blocks/Objective/ObjectivePik';
import CertificationsPik from './blocks/Certifications/CertificationsPik';
import ContactPik from './blocks/Contact/ContactPik';
import EducationA from './blocks/Education/EducationA';
import HeadingPik from './blocks/Heading/HeadingPik';
import HobbiesA from './blocks/Hobbies/HobbiesA';
import LanguagesPik from './blocks/Languages/LanguagesPik';
import PageContext from '../contexts/PageContext';
import ProjectsA from './blocks/Projects/ProjectsA';
import ReferencesPik from './blocks/References/ReferencesPik';
import SkillsP from './blocks/Skills/SkillsP';
import WorkPik from './blocks/Work/WorkPik';
import AsyncImage from './../components/shared/AsyncImage';


const Blocks = {
  objective: ObjectivePik,
  work: WorkPik,
  education: EducationA,
  projects: ProjectsA,
  awards: AwardsPik,
  certifications: CertificationsPik,
  skills: SkillsP,
  hobbies: HobbiesA,
  languages: LanguagesPik,
  references: ReferencesPik,
};

const Pikachu = ({ data }) => {
  const layout = data.metadata.layout.pikachu;
  const myBorder = data.metadata.pikachu.primary;

  const Photo = () =>
    data.profile.photograph !== '' && (
      <img
        className="photo"
        style={{
          outlineColor : data.metadata.pikachu.background,
        }}
        src={data.profile.photograph}
        alt={data.profile.firstName}
      />
    );

  // const Photo = () =>
  // data.profile.photograph && (
  //   <AsyncImage
  //     className="photo"
  //     style={{
  //       outlineColor : data.metadata.pikachu.background,
  //     }}
  //     src={data.profile.photograph}
  //     alt={data.profile.firstName}
  //   />
  // );

  
    const PikUfall = () => 
    data.metadata.trainingProgram.logo3 !== '' ? (
      <div className="containUFA" style={{justifyContent:'space-between'}}>
        <div className="UFA text-xs" style={{ width:"25%", borderColor:myBorder}}>
          <img src={data.metadata.trainingProgram.logo1}  style={{width:"90%"}}/>
        </div>
        <div className="UFA text-xs" style={{ width:"25%", borderColor:myBorder}}>
          <img src={data.metadata.trainingProgram.logo2}  style={{width:"90%"}}/>
        </div>
        <div className="UFA text-xs" style={{ width:"25%", borderColor:myBorder}}>
          <img src={data.metadata.trainingProgram.logo3}  style={{width:"90%"}}/>
        </div>
        <div className="UFA text-xs" style={{ width:"25%", borderColor:myBorder}}>
          <img src={data.metadata.trainingProgram.logoAFi24}  style={{width:"90%"}}/>
        </div>
      </div>
    ): data.metadata.trainingProgram.logo2 !== '' ?(
      <div className="containUFA" style={{justifyContent:'space-between'}}>
            <div className="UFA text-xs" style={{borderColor:myBorder}}>
              <img src={data.metadata.trainingProgram.logo1} />
            </div>
            <div className="UFA text-xs" style={{borderColor:myBorder}}>
              <img src={data.metadata.trainingProgram.logo2} />
            </div>
            <div className="UFA text-xs" style={{borderColor:myBorder}}>
              <img src={data.metadata.trainingProgram.logoAFi24} />
            </div>
          </div>
    ):(
      <div className="containUFA" style={{justifyContent:'space-evenly'}}>
            <div className="UFA text-xs" style={{ border:"none", float:"left"}}>
              <img src={data.metadata.trainingProgram.logo1} />              
            </div>
            <div className="UFA text-xs" style={{ border:"none", float:"right"}}>
              <img src={data.metadata.trainingProgram.logoAFi24} />
            </div>
          </div>
    );
  
  return (

  <PageContext.Provider value={{ data, heading: HeadingPik }}>
    <div
      id="pageTemplate"
      // className="rounded"
      style={{
        fontFamily: data.metadata.font,
        color: data.metadata.pikachu.text,
        backgroundColor: data.metadata.pikachu.background,
      }}
    >
      
      <div id="pikachu" className="grid grid-cols-12">
        <div className="col-span-4 cg"
             style={{
               color: data.metadata.pikachu.background,
               backgroundColor: data.metadata.pikachu.primary,
             }}
        >
          <div className="p-6 grid gap-4" >
            <div className="flex items-center">
                <Photo />
            </div>
            <ContactPik />
            {layout[0] &&
            layout[0].map((x) => {
              const Component = Blocks[x];
              return Component && <Component key={x} />;
            })}
          </div>
        </div>
        <div className="col-span-8 cd">
          <div>
            <div className="pt-4 pr-3 pb-0 pl-3" >
              <div className="flex column relative name"
              style={{
                color:  data.metadata.pikachu.primary,
              }} >
                <h1 className="text-4xl leading-tight">
                  {data.profile.firstName}
                </h1>
              </div>
              <div className="flex column relative name"
              style={{
                color:  data.metadata.pikachu.primary
               }} >
                <h1 className="text-4xl font-bold leading-tight uppercase">
                  {data.profile.lastName}
                </h1>
              </div>
              <div className="text-sm font-medium tracking-wide sujet">
                {data.profile.subtitle}
              </div>
              <div className="font-bold text-xl formation"
              style={{
                color:  data.metadata.pikachu.primary
               }}>{data.metadata.trainingProgram.nomformation}
              </div>
              <PikUfall />
            </div>
          </div>
          <div className="p-6 grid" >
            {layout[1] &&
            layout[1].map((x) => {
              const Component = Blocks[x];
              return Component && <Component key={x} />;
            })}
          </div>
        </div>
      </div>
    </div>
  </PageContext.Provider>
  );
};


export default Pikachu;

import React from 'react';
import AwardsStel from './blocks/Awards/AwardsStel';
import ObjectivePik from './blocks/Objective/ObjectivePik';
import CertificationsStel from './blocks/Certifications/CertificationsStel';
import ContactStel from './blocks/Contact/ContactStel';
import EducationA from './blocks/Education/EducationA';
import HeadingStel from './blocks/Heading/HeadingStel';
import HobbiesA from './blocks/Hobbies/HobbiesA';
import LanguagesA from './blocks/Languages/LanguagesA';
import PageContext from '../contexts/PageContext';
import ProjectsStel from './blocks/Projects/ProjectsStel';
import ReferencesStel from './blocks/References/ReferencesStel';
import SkillsP from './blocks/Skills/SkillsP';
import WorkStel from './blocks/Work/WorkStel';
import {useSelector} from '../contexts/ResumeContext';
import AsyncImage from './../components/shared/AsyncImage';
import { useTranslation } from 'react-i18next';

const Blocks = {
  work: WorkStel,
  objective: ObjectivePik,
  education: EducationA,
  projects: ProjectsStel,
  awards: AwardsStel,
  certifications: CertificationsStel,
  skills: SkillsP,
  hobbies: HobbiesA,
  languages: LanguagesA,
  references: ReferencesStel,
};



const Onyx = ({ data }) => {
  const layout = data.metadata.layout.stellar;
  const variableTemplate = data.metadata.template;

  const Ufall = () =>
    data.metadata.trainingProgram.logo3 !== '' ? (
      <div className="grid grid-cols-2 gap-2 ufall">
        <div className="UFA">
          <img src={data.metadata.trainingProgram.logo1} />
        </div>
        <div className="UFA">
          <img src={data.metadata.trainingProgram.logo2}/>
        </div>
        <div className="UFA">
          <img src={data.metadata.trainingProgram.logo3}/>
        </div>
        <div className="UFA">
          <img src={data.metadata.trainingProgram.logoAFi24}/>
        </div>
      </div>
    ): data.metadata.trainingProgram.logo2 !== '' ?(
      <div className="">
        <div className="UFA" style={{width:"48%",marginRight:"10px",display:"inline-block"}}>
          <img src={data.metadata.trainingProgram.logo1}/>
        </div>
        <div className="UFA" style={{width:"48%",display:"inline-block"}}>
          <img src={data.metadata.trainingProgram.logo2}/>
        </div>
        <div className="UFA" style={{width:"49%",margin:"0 auto"}}>
          <img src={data.metadata.trainingProgram.logoAFi24}/>
        </div>
      </div>
    ): (
      <div className="grid grid-cols-2 gap-2 ufall">
        <div className="UFA">
          <img src={data.metadata.trainingProgram.logo1}/>
        </div>
        <div className="UFA">
          <img src={data.metadata.trainingProgram.logoAFi24}/>
        </div>
      </div>
    );

  return (
    <PageContext.Provider value={{ data, heading: HeadingStel }}>
      <div
        id="page"
        className="stellarGab"
        style={{
          fontFamily: data.metadata.font,
          color: useSelector(`metadata.${variableTemplate}.text`),
          backgroundColor: useSelector(`metadata.${variableTemplate}.background`),
        }}
      >
        <div id="onyx">
          <div className="header">
            <div className='grid grid-cols-12 items-start contain'>
              <div className="col-span-9 nom">

                <div className="contain-name">
                  <div className="flex column relative firstname">
                    <h1 className="text-4xl font-bold leading-tight uppercase">
                      {data.profile.firstName}
                    </h1>
                  </div>
                  <div className="flex column relative name">
                    <h1 className="text-3xl leading-tight uppercase">
                      {data.profile.lastName}
                    </h1>
                  </div>
                  <div className="font-bold text-xl formation">
                    {data.metadata.trainingProgram.nomformation}
                  </div>
                </div>
                <div className="sujetContain">
                  <div className="text-sm font-bold tracking-wide sujet" style={{backgroundColor: useSelector(`metadata.${variableTemplate}.primary`), color: useSelector(`metadata.${variableTemplate}.background`)}}>
                  {data.profile.subtitle}
                  </div>
                  <div className="sujetGraf" style={{backgroundColor: useSelector(`metadata.${variableTemplate}.text`)}}>&nbsp;</div>
                </div>
              </div>
              <div className="col-span-3">
                {/*{data.profile.photograph && (*/}
                {/*  <img*/}
                {/*    className="rounded object-cover mr-4 photo"*/}
                {/*    src={data.profile.photograph}*/}
                {/*    alt={data.profile.firstName}*/}
                {/*  />*/}
                {/*)}*/}
                {data.profile.photograph && (
                  <AsyncImage
                    src={data.profile.photograph}
                    alt={data.profile.firstName}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-12 mainOnyx">
            <div className="col-span-4 cg">
              <div className="grid">

                <ContactStel />
                {layout[1] &&
                  layout[1].map((x) => {
                    const Component = Blocks[x];
                    return Component && <Component key={x} />;
                  })}
              </div>
              <div className='etablissement'>
                <div className='heading'>
                  <h6 className="text-lg uppercase tracking-wide title" style={{color: data.metadata[variableTemplate].text,fontWeight:'bold'}}>établissement</h6>
                </div>
                <Ufall/>
              </div>
            </div>
            <div className="col-span-8 cd">
            <div className="grid">

              {layout[0] && layout[0].map((x) => {
                const Component = Blocks[x];
                return Component && <Component key={x} />;
              })}
            </div>
          </div>
          </div>
        </div>
      </div>
    </PageContext.Provider>
  );
};


export default Onyx;

import { FaCaretRight } from 'react-icons/fa';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import React, { memo, useContext } from 'react';
import { hasAddress, isItemVisible, safetyCheck } from '../../../utils';
import Icons from '../Icons';
import PageContext from '../../../contexts/PageContext';
import {FaPaperPlane} from 'react-icons/fa';

const ContactItem = ({ value, icon, link }) => {
  const { data } = useContext(PageContext);
  const Icon = get(Icons, icon && icon.toLowerCase(), FaCaretRight);
  const variableTemplate = data.metadata.template;

  return value ? (
    <div className="flex items-center text-xs">
      <Icon
        size="11px"
        className="mr-2"
        style={{ color: data.metadata[variableTemplate].primary}}
      />
      {link ? (
        <a href={link} target="_blank" rel="noopener noreferrer">
          <span className="font-medium break-all">{value}</span>
        </a>
      ) : (
        <span className="font-medium">{value}</span>
      )}
    </div>
  ) : null;
};

const ContactStel = () => {
  const { t } = useTranslation();
  const { data, heading: Heading } = useContext(PageContext);
  const variableTemplate = data.metadata.template;
  const Icon = FaPaperPlane;

  return (
    <div className="text-sm grid gap-2 module">
      <Heading parent='contact'>{data.profile.heading}</Heading>
      <div className='grid grid-cols-2 gap-1'>
      <ContactItem
        label={t('shared.forms.drivingLicence')}
        value={data.profile.drivingLicence}
        icon="driving"
      />
      <ContactItem
        label={t('shared.forms.mobility')}
        value={`Mobilité : ${data.profile.mobility}`}
        icon="mobility"
      />
      {safetyCheck(data.social) &&
        data.social.items.map(
          (x) =>
            isItemVisible(x) && (
              <ContactItem
                key={x.id}
                value={x.username}
                icon={x.network}
                link={x.url}
              />
            ),
        )}
      <ContactItem
        label={t('shared.forms.website')}
        value={data.profile.website}
        icon="website"
        link={data.profile.website}
      />
        </div>
        {hasAddress(data.profile.address) && (
        <ContactItem
          label={t('shared.forms.address')}
          value={`${[
            data.profile.address.line1,
            data.profile.address.line2,
            data.profile.address.city,
          ]
            .filter(Boolean)
            .join(', ')} ${data.profile.address.pincode}`}
          icon="address"
        />
      )}
    </div>
  );
};

export default memo(ContactStel);

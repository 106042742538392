import { useTranslation } from 'react-i18next';
import React, { memo, useContext } from 'react';
import PageContext from '../../../contexts/PageContext';
import { formatDateRange, isItemVisible, safetyCheck, visibleItems } from "../../../utils";
import parse from "html-react-parser";

const ProjectItem = ({ item, language }) => {
  const { t } = useTranslation();
  const {data} = useContext(PageContext);
  const variableTemplate = data.metadata.template;
  const layout = data.metadata.layout[variableTemplate];
  const whatCol = layout[0].indexOf('projects');
  const myBlock = (whatCol == "-1") ? "flex justify-between items-center" : "";
  return (
    <div className="ref">
      <div className={myBlock}>
        <div className="flex flex-col">
          <h6 className="font-semibold text-xs">{item.title}</h6>
          {item.link && (
            <a href={item.link} className="text-xs">
              {item.link}
            </a>
          )}
        </div>
        {item.startDate && (
          <h6 className="text-xs font-medium mb-1">
            {formatDateRange(
              {
                startDate: item.startDate,
                endDate: item.endDate,
                language,
              },
              t,
            )}
          </h6>
        )}
      </div>
      {item.summary && (
        <div className="text-xs">{parse(item.summary)}</div>
      )}
    </div>
  );
};

const ProjectsWind = () => {
  const { data, heading: Heading } = useContext(PageContext);

  return safetyCheck(data.projects) ? (
    <div className={(visibleItems(data.projects.items)) ? "module projects" : "erase"}>
      <Heading parent='projects'>{visibleItems(data.projects.items) && data.projects.heading}</Heading>
      <div className="grid gap-4">
        {data.projects.items.map(
          (x) =>
            isItemVisible(x) && (
              <ProjectItem
                key={x.id}
                item={x}
                language={data.metadata.language}
              />
            ),
        )}
      </div>
    </div>
  ) : null;
};

export default memo(ProjectsWind);

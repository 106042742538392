import React, { memo, useContext } from 'react';
import { isItemVisible, safetyCheck, visibleItems } from "../../../utils";
import PageContext from '../../../contexts/PageContext';
import { FaFutbol} from 'react-icons/fa';
import parse from 'html-react-parser';


const HobbyA = ({ id, name }) => (
  <div key={id} className="text-sm myLi">
    {parse(name)}
  </div>
);

const HobbiesDra = () => {
  const { data, heading: Heading } = useContext(PageContext);
  const variableTemplate = data.metadata.template;
  const Icon = FaFutbol;

  /*colG or colD*/
  const layout = data.metadata.layout[variableTemplate];
  const whatCol = layout[0].indexOf("hobbies");
  const myBorder = (whatCol == "-1") ? "grid gap-1 dcontent contain" : "grid gap-1 contain";

  return safetyCheck(data.hobbies) ? (
    <div className={(visibleItems(data.hobbies.items)) ? "module hobbies" : "erase"}>
      <div className='flex items-center'>
        <Icon
          size="1.15em"
          className="mr-2"
          style={{ color: data.metadata[variableTemplate].primary}}
        />
        <div className="font-bold uppercase text-lg " style={{color: data.metadata[variableTemplate].text}}>
          {data.hobbies.heading}</div>
      </div>
      <div className={myBorder} style={{borderColor: data.metadata[variableTemplate].primary}} >
        {data.hobbies.items.map((x) => isItemVisible(x) && HobbyA(x))}
      </div>
    </div>
  ) : null;
};

export default memo(HobbiesDra);

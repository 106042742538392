import React, { memo, useContext } from 'react';
import { isItemVisible, safetyCheck, visibleItems } from "../../../utils";
import PageContext from '../../../contexts/PageContext';
import parse from "html-react-parser";

const HobbyA = ({ id, name }) => {
  return(
    <div key={id} className="text-sm myLi">
      {parse(name)}
    </div>
  )
};

const HobbiesRock = () => {
  const { data, heading: Heading } = useContext(PageContext);
  const variableTemplate = data.metadata.template;
  const layout = data.metadata.layout[variableTemplate];
  const whatCol = layout[0].indexOf("hobbies");
  const myBlock = (whatCol == "-1") ? "grid grid-cols-3 gap-1 contain" : "grid";
  const myBorder = (whatCol == "-1") ? "cgUnderNone" : "cgUnderline";


  return safetyCheck(data.hobbies) ? (
    <div className={(visibleItems(data.hobbies.items)) ? "module hobbies" : "erase"}>
      <Heading parent='hobbies'>{visibleItems(data.hobbies.items) && data.hobbies.heading}</Heading>
      <div className={myBlock}>
        {data.hobbies.items.map((x) => isItemVisible(x) && HobbyA(x))}
      </div>
      <div className={myBorder} style={{borderColor: data.metadata[variableTemplate].text}}></div>

    </div>
  ) : null;
};

export default memo(HobbiesRock);

import React, { memo, useContext } from 'react';
import { safetyCheck, visibleItems } from "../../../utils";
import PageContext from '../../../contexts/PageContext';
import parse from "html-react-parser";

const ObjectiveA = () => {
  const { data, heading: Heading } = useContext(PageContext);

  return (
    safetyCheck(data.objective, 'body') && (
      <div className={((data.objective.body).length > 7) ? "module objective" : "erase"}>
          {((data.objective.body).length > 7) && <Heading parent='objective'>{data.objective.heading}</Heading>}
          <div className="text-sm">{parse(data.objective.body)}</div>

      </div>
    )
  );
};

export default memo(ObjectiveA);

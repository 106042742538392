import React, { memo, useContext } from 'react';import PageContext from '../../../contexts/PageContext';

const HeadingLight = ({ children }) => {
  const { data } = useContext(PageContext);
  const variableTemplate = data.metadata.template;

  return (
    <h6 className= "mb-2 border-b-2 font-bold uppercase tracking-wide text-lg" style={{lineHeight:"normal", borderColor:data.metadata[variableTemplate].primary}}>
      {children}
    </h6>
  );
};

export default memo(HeadingLight);

import React from 'react';
import { hexToRgb } from '../utils';
import AwardsDra from './blocks/Awards/AwardsDra';
import ObjectiveDra from './blocks/Objective/ObjectiveDra';
import CertificationsDra from './blocks/Certifications/CertificationsDra';
import ContactDra from './blocks/Contact/ContactDra';
import EducationDra from './blocks/Education/EducationDra';
import HeadingA from './blocks/Heading/HeadingA';
import HobbiesDra from './blocks/Hobbies/HobbiesDra';
import LanguagesDra from './blocks/Languages/LanguagesDra';
import PageContext from '../contexts/PageContext';
import ProjectsDra from './blocks/Projects/ProjectsDra';
import ReferencesDra from './blocks/References/ReferencesDra';
import SkillsDra from './blocks/Skills/SkillsDra';
import WorkDra from './blocks/Work/WorkDra';
import {useSelector} from '../contexts/ResumeContext';

const Blocks = {
  work: WorkDra,
  objective: ObjectiveDra,
  education: EducationDra,
  projects: ProjectsDra,
  awards: AwardsDra,
  certifications: CertificationsDra,
  skills: SkillsDra,
  hobbies: HobbiesDra,
  languages: LanguagesDra,
  references: ReferencesDra,
};

const Dracofeu = ({ data }) => {
  const layout = data.metadata.layout.dracofeu;  
  const variableTemplate = data.metadata.template;
  const { r, g, b } = hexToRgb(useSelector(`metadata.${variableTemplate}.text`)) || {};

  const Ufall = () =>
  data.metadata.trainingProgram.logo3 !== '' ? (
    <div className="flex justify-between items-center ufall">
      <div className="UFA text-xs" style={{ width:"25%"}}>
        <img src={data.metadata.trainingProgram.logo1} style={{width:"90%"}} />
      </div>
      <div className="UFA text-xs" style={{ width:"25%"}}>
        <img src={data.metadata.trainingProgram.logo2} style={{width:"90%"}} />
      </div>
      <div className="UFA text-xs" style={{ width:"25%"}}>
        <img src={data.metadata.trainingProgram.logo3} style={{width:"90%"}} />
      </div>
      <div className="UFA text-xs" style={{ width:"25%"}}>
        <img src={data.metadata.trainingProgram.logoAFi24} style={{width:"90%"}} />
      </div>
    </div>
  ):(
    <div className="flex justify-between items-center ufall">
      <div className="UFA text-xs" style={{ width:"33%", borderColor: useSelector(`metadata.${variableTemplate}.primary`),}}>
        <img src={data.metadata.trainingProgram.logo1} style={{width:"90%"}} />
      </div>
      <div className="UFA text-xs" style={{ width:"33%"}}>
        <img src={data.metadata.trainingProgram.logo2} style={{width:"90%"}} />
      </div>
      <div className="UFA text-xs" style={{ width:"33%"}}>
        <img src={data.metadata.trainingProgram.logoAFi24} style={{width:"90%"}} />
      </div>
    </div>
  );

  /* info logotype */
  var lettre1 = data.profile.firstName.substr(0,1); 
  var lettre2 = data.profile.lastName.substr(0,1); 

  return (
  <PageContext.Provider value={{ data, heading: HeadingA }}>
    <div
      id="page"
      className="p-8 rounded dracoGab"
        style={{
          fontFamily: data.metadata.font,
          color: useSelector(`metadata.${variableTemplate}.text`),
          backgroundColor: useSelector(`metadata.${variableTemplate}.background`),
        }}
      >
        <div id="dracofeu">
          <div className="grid grid-cols-12 items-start">
            <div className="col-span-1 lettrine" style={{
            fontFamily: data.metadata.font,
            color: useSelector(`metadata.${variableTemplate}.background`),
            backgroundColor: useSelector(`metadata.${variableTemplate}.primary`),
          }}>
              <span id="let1" >{lettre1}</span>
              <span id="slash" style={{backgroundColor: useSelector(`metadata.${variableTemplate}.background`),}}></span>
              <span id="let2">{lettre2}</span>
            </div>
            <div className="col-span-8 nom">
              <div className="contain-name" style={{
                  color: useSelector(`metadata.${variableTemplate}.text`)
                }}>
                <div className="flex column relative firstname">
                  <h1 className="text-3xl leading-tight">
                    {data.profile.firstName}
                  </h1>
                </div>
                <div className="flex column relative name">
                  <h1 className="text-3xl font-bold leading-tight uppercase">
                    {data.profile.lastName}
                  </h1>
                </div>
                <div className="font-bold text-xl formation">
                  {data.metadata.trainingProgram.nomformation}
                </div>
              </div>
              <div className="contain-ufa">
                <Ufall/>
              </div>
              <div className="contain-project" style={{ backgroundColor: `rgba(${r}, ${g}, ${b}, 0.05)`,}}>
                <div className="text-sm font-semibold tracking-wide sujet">
                    {data.profile.subtitle}
                  </div>
              </div>
            </div>
            <div className="col-span-3 photo">
              {data.profile.photograph && (
                <img
                  className="rounded object-cover mr-4 "
                  src={data.profile.photograph}
                  alt={data.profile.firstName}
                  style={{alignSelf:"center", borderColor: useSelector(`metadata.${variableTemplate}.primary`),}}
                />
              )}
            </div>
          </div>
        <div className="grid grid-cols-12 allContain">
          <div className="col-span-4">
            <div className="grid gap-4 colgauche">
              <ContactDra />
              {layout[0] &&
              layout[0].map((x) => {
                const Component = Blocks[x];
                return Component && <Component key={x} />;
              })}
            </div>
          </div>
          <div className="col-span-8">
            <div className="p-6 grid gap-4 coldroite">
              {layout[1] && layout[1].map((x) => {
                const Component = Blocks[x];
                return Component && <Component key={x} />;
              })}
            </div>
          </div>

          
        </div>
      </div>
    </div>
  </PageContext.Provider>
  );
};


export default Dracofeu;

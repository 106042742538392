import { useTranslation } from 'react-i18next';
import React, { memo, useContext } from 'react';
import PageContext from '../../../contexts/PageContext';
import { formatDate, isItemVisible, safetyCheck, visibleItems } from "../../../utils";
import {FaBookOpen} from 'react-icons/fa';
import parse from "html-react-parser";

const EducationItem = ({ item, language }) => {

  return (
    <div className="ref">
      <div className="flex justify-between reference">
        <div className='modTitle'>

        <div className="text-sm"><strong>{item.degree} </strong>{item.field}</div>
          <div className='text-xs font-bold '>{item.gpa && (<span>Mention : {item.gpa}</span>)}</div>
          <div className='text-xs'>{item.institution}</div>
        </div>

        <div className="modDate">
        {item.startDate && (
          <div className="text-xs">
            {formatDate({ date: item.startDate, language })}
          </div>
        )}
          {item.startDate && (
            <div className="text-xs">
              {formatDate({ date: item.endDate, language })}
            </div>
          )}
          
        </div>
      </div>
      <div className="" >
        {item.summary && (
          <div className="mt-2 text-sm">{parse(item.summary)}</div>
        )}
      </div>
    </div>
  );
};

const EducationWind = () => {
  const { data, heading: Heading } = useContext(PageContext);

  return safetyCheck(data.education) ? (
    <div className={(visibleItems(data.education.items)) ? "module education" : "erase"}>
      <Heading parent='education'>{visibleItems(data.education.items) && data.education.heading}</Heading>
      <div className="grid gap-4 contain">
        {data.education.items.map(
          (x) =>
            isItemVisible(x) && (
              <EducationItem
                key={x.id}
                item={x}
                language={data.metadata.language}
              />
            ),
        )}
      </div>
    </div>
  ) : null;
};

export default memo(EducationWind);

import React, { memo, useContext } from 'react';
import { isItemVisible, safetyCheck, visibleItems } from "../../../utils";
import PageContext from '../../../contexts/PageContext';
import { FaFlag } from 'react-icons/fa';

const LanguageItem = ({ id, name, fluency }) => {
  return(
    <div key={id} className="langContain">
      <div className="font-bold text-sm">{name}</div>
      <div className="text-xs">{fluency}</div>
    </div>
  )
};

const LanguagesLine = () => {
  const { data } = useContext(PageContext);
  const variableTemplate = data.metadata.template;
  const Icon = FaFlag;
  return safetyCheck(data.languages) ? (
    <div className={(visibleItems(data.languages.items)) ? "module languages" : "erase"}>
      <div className='flex items-center'>
        <Icon
          size="1.15em"
          className="mr-2"
          style={{ color: data.metadata[variableTemplate].primary}}
        />
        <div className="font-bold uppercase text-lg" style={{color: data.metadata[variableTemplate].text}}>
          {data.languages.heading}</div>
      </div>
      <div className="grid wcontent" style={{borderColor: data.metadata[variableTemplate].primary}}>
        {data.languages.items.map((x) => isItemVisible(x) && LanguageItem(x))}
      </div>
    </div>
  ) : null;
};

export default memo(LanguagesLine);



import { useTranslation } from 'react-i18next';
import React, { memo, useContext } from 'react';
import PageContext from '../../../contexts/PageContext';
import { formatDate, isItemVisible, safetyCheck, visibleItems } from "../../../utils";
import parse from "html-react-parser";

const WorkItem = ({ item, language }) => {
  return (
    <div className="ref">
      <div className="flex justify-between reference"> 
        <h6 className='text-sm font-bold modTitle'>{item.company}</h6>
        <div className='modDate'>
          {item.startDate && (
          <div className="text-xs">
            {formatDate({ date: item.startDate, language })}
          </div>
        )}
          {item.startDate && (
            <div className="text-xs">
              {formatDate({ date: item.endDate, language })}
            </div>
          )}
      </div>
      </div>
      <div>
        <span className="text-xs">{item.position}</span>
        {item.summary.length > 7 && (
          <div className="mt-1 text-sm">{parse(item.summary)}</div>
        )}
        </div>
        
      </div>
  );
};

const WorkWind = () => {
  const { data, heading: Heading } = useContext(PageContext);

  return safetyCheck(data.work) ? (
    <div className={(visibleItems(data.work.items)) ? "module work" : "erase"}>
      <Heading parent='languages'>{data.work.heading}</Heading>
      <div className="grid gap-2">
        {data.work.items.map(
          (x) =>
            isItemVisible(x) && (
              <WorkItem key={x.id} item={x} language={data.metadata.language} />
            ),
        )}
      </div>
    </div>
  ) : null;
};

export default memo(WorkWind);

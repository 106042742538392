import React, { memo, useContext } from 'react';
import { isItemVisible, safetyCheck, visibleItems } from "../../../utils";
import PageContext from '../../../contexts/PageContext';
import { ChunkProgressBar } from '@progress/kendo-react-progressbars';
import {useSelector} from "../../../contexts/ResumeContext";
import {FaChartLine} from 'react-icons/fa';

const SkillItem = ({ id, name, level }) => {
  const {data} = useContext(PageContext);
  const variableTemplate = data.metadata.template;
  const chunks = 5;
  const emptyStyles = { background: useSelector(`metadata.${variableTemplate}.text`) };
  const progressStyles = { background: useSelector(`metadata.${variableTemplate}.primary`) };
  const completeness =level*20;

  return (
    <div key={id} className="progressAll">
      <h6 className="font-bold text-xs">{name}</h6>
      <ChunkProgressBar
        value={completeness}
        emptyStyle={emptyStyles}
        progressStyle={progressStyles}
        chunkCount={chunks}
      />
    </div>
  );
};

const SkillsLight = () => {
  const { data } = useContext(PageContext);
  const variableTemplate = data.metadata.template;
  const Icon = FaChartLine;

  return safetyCheck(data.skills) ? (
    <div className={(visibleItems(data.skills.items)) ? "module skills" : "erase"}>
      {visibleItems(data.skills.items) &&
        <div className='flex items-center'>
          <Icon
            size="1.15em"
            className="mr-2"
            style={{ color: data.metadata[variableTemplate].primary }}
          />
          <h6 className="text-lg font-bold uppercase"
              style={{ color: data.metadata[variableTemplate].text }}>
            {data.skills.heading}
          </h6>
        </div>
      }
      <div className="grid">
        {data.skills.items.map((x) => isItemVisible(x) && SkillItem(x))}
      </div>
    </div>
  ) : null;
};

export default memo(SkillsLight);
import React, { memo, useContext } from "react";
import { formatDate, isItemVisible, safetyCheck, visibleItems } from "../../../utils";
import PageContext from '../../../contexts/PageContext';
import parse from "html-react-parser";

const CertificationItem = ({ item, language }) => {
  const {data} = useContext(PageContext);
  const variableTemplate = data.metadata.template;
  const layout = data.metadata.layout[variableTemplate];

  const whatCol = layout[0].indexOf("certifications");

  return(
    <div>
      <div className="flex justify-between items-center">
        <div className="flex flex-col text-left mr-2">
          <h6 className="font-bold text-sm">{item.title}</h6>
          <span className="text-xs">{item.issuer}</span>
        </div>
        {item.date && (
          <h6 className="text-xs text-right self-start" >
            {formatDate({ date: item.date, language })}
          </h6>
        )}
      </div>
      {item.summary && (
        <div className="mt-2 text-sm">{parse(item.summary)}</div>
      )}
    </div>
  );
};

const CertificationsRock = () => {
  const { data, heading: Heading } = useContext(PageContext);
  const variableTemplate = data.metadata.template;

  /*colG or colD* -1 à droite*/
  const layout = data.metadata.layout[variableTemplate];
  const whatCol = layout[0].indexOf("certifications");
  const myBlock = (whatCol == "-1") ? "grid grid-cols-2 gap-2" : "grid gap-1";
  const myBorder = (whatCol == "-1") ? "cgUnderNone" : "cgUnderline";

  return safetyCheck(data.certifications) ? (
    <div className={(visibleItems(data.certifications.items)) ? "module certifications" : "erase"}>
      <Heading parent='certifications'>{visibleItems(data.certifications.items) && data.certifications.heading}</Heading>
      <div className={myBlock}>
        {data.certifications.items.map(
          (x) =>
            isItemVisible(x) && (
              <CertificationItem
                key={x.id}
                item={x}
                language={data.metadata.language}
              />

            ),
        )}
      </div>
      <div className={myBorder} style={{borderColor: data.metadata[variableTemplate].text}}></div>
    </div>
  ) : null;
};

export default memo(CertificationsRock);

import React, { memo, useContext } from 'react';
import PageContext from '../../../contexts/PageContext';

const HeadingWind = ({ children }) => {
  const { data } = useContext(PageContext);
  const variableTemplate = data.metadata.template;

  return (
      <div className='heading'>
        <h6 className="text-lg font-bold uppercase tracking-wide title" style={{color: data.metadata[variableTemplate].text}}>
          {children}
        </h6>
      </div>
  );
};

export default memo(HeadingWind);

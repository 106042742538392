import { useTranslation } from 'react-i18next';
import React, { memo, useContext } from 'react';
import PageContext from '../../../contexts/PageContext';
import { formatDateRange, isItemVisible, safetyCheck } from '../../../utils';


// items-center retiré de la div 1
const WorkItem = ({ item, language }) => {
  const { t } = useTranslation();
  const {data} = useContext(PageContext);
  const variableTemplate = data.metadata.template;
  return (
    <div className="ref">
      <div className="flex justify-between reference"> 
        <div className="flex flex-col text-left mr-2">
          <h6 className="font-semibold text-sm">{item.company}</h6>
          <span className="text-xs">{item.position}</span>
        </div>
        {item.startDate && (
          <h6 className="text-xs font-medium text-right" style={{
            color: data.metadata[variableTemplate].primary,
          }} >
            {formatDateRange(
              {
                startDate: item.startDate,
                endDate: item.endDate,
                language,
              },
              t,
            )}
          </h6>
        )}
      </div>
      {item.summary && (
        <div className="mt-2 text-sm">{item.summary}</div>
      )}
      <div className="underline" style={{
               borderColor: data.metadata[variableTemplate].primary
             }}></div>
    </div>
  );
};

const WorkA = () => {
  const { data, heading: Heading } = useContext(PageContext);
  return safetyCheck(data.work) ? (
    <div className="module">
      <Heading>{data.work.heading}</Heading>
      <div className="grid gap-4">
        {data.work.items.map(
          (x) =>
            isItemVisible(x) && (
              <WorkItem key={x.id} item={x} language={data.metadata.language} />
            ),
        )}
      </div>
    </div>
  ) : null;
};

export default memo(WorkA);

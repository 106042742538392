import React, { memo, useContext } from 'react';
import PageContext from '../../../contexts/PageContext';

const HeadingRock = ({parent, children }) => {
  const { data } = useContext(PageContext);
  const variableTemplate = data.metadata.template;
  /*colG or colD*/
  const layout = data.metadata.layout[variableTemplate];
  const whatCol = layout[0].indexOf(parent);
  const myClass = (whatCol == "-1") ? 'mb-2 border-b-2 font-bold uppercase tracking-wide text-lg' : 'mb-2 font-bold uppercase tracking-wide text-lg';

  return (
    <h6 className= {myClass}>
      {children}
    </h6>
  );
};

export default memo(HeadingRock);

import React, { memo, useContext } from "react";
import { isItemVisible, safetyCheck, visibleItems } from "../../../utils";
import PageContext from '../../../contexts/PageContext';
import parse from "html-react-parser";
import {FaUserTie} from 'react-icons/fa';

const ReferenceItem = ({ id, name, position, phone, email, summary }) => (
  <div key={id} className="flex flex-col">
    <h6 className="font-bold text-sm">{name}</h6>
    <span className="text-xs">{position}</span>
    <span className="text-xs">{phone}</span>
    <span className="text-xs">{email}</span>
    {summary && (
      <div className="mt-2 text-sm">{parse(summary)}</div>
    )}
  </div>
);


const ReferencesLine = () => {
  const { data} = useContext(PageContext);
  const variableTemplate = data.metadata.template;
  const Icon = FaUserTie;

  return safetyCheck(data.references) ? (
    <div className={(visibleItems(data.references.items)) ? "module references" : "erase"}>
      <div className='flex items-center'>
        <Icon
          size="1.15em"
          className="mr-2"
          style={{ color: data.metadata[variableTemplate].primary}}
        />
        <h6 className="text-lg font-bold uppercase" style={{ color: data.metadata[variableTemplate].text}} >
          {data.references.heading}
        </h6>
      </div>
      <div className="grid gap-2">
        {data.references.items.map((x) => isItemVisible(x) && ReferenceItem(x))}
      </div>
    </div>
  ) : null;
};

export default memo(ReferencesLine);

import React, { memo, useContext } from 'react';
import { isItemVisible, safetyCheck, visibleItems } from "../../../utils";
import PageContext from '../../../contexts/PageContext';
import parse from "html-react-parser";

const ReferenceItem = ({ id, name, position, phone, email, summary }) => {
  return(
  <div key={id} className="flex flex-col ref" style={{marginTop:"5px",}}>
    <div className="reference">
      <h6 className='font-bold text-sm'>{name}</h6>
    </div>
    <div>
      <span className="text-xs" style={{display:"block"}}>{position}</span>
      <span className="text-xs" style={{display:"block"}}>{phone}</span>
      <span className="text-xs" style={{display:"block"}}>{email}</span>
      {summary.length > 7 && (
        <div className="mt-2 text-sm">{parse(summary)}</div>
      )}
    </div>
  </div>
  );
};


const ReferencesStel = () => {
  const { data, heading: Heading } = useContext(PageContext);
  const variableTemplate = data.metadata.template;
  const layout = data.metadata.layout[variableTemplate];
  const whatCol = layout[0].indexOf("references");
  const myBlock = (whatCol == "-1") ? "grid gap-1" : "grid grid-cols-3 gap-1";


  return safetyCheck(data.references) ? (
    <div className={(visibleItems(data.references.items)) ? "module references" : "erase"}>
      <Heading parent='references'>{data.references.heading}</Heading>
      <div className={myBlock}>
        {data.references.items.map((x) => isItemVisible(x) && ReferenceItem(x))}
      </div>
    </div>
  ) : ("");
};

export default memo(ReferencesStel);

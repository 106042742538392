import React, { memo, useContext } from 'react';
import { formatDat, isItemVisible, safetyCheck, visibleItems } from "../../../utils";
import PageContext from '../../../contexts/PageContext';
import parse from "html-react-parser";

const AwardItem = ({ item, language }) => {
  return(
    <div>
      <div className="flex justify-between items-center">
        <div className="flex flex-col text-left mr-2">
          <h6 className="font-semibold text-sm">{item.title}</h6>
          <span className="text-xs">{item.awarder}</span>
        </div>
        {item.date && (
          <h6 className="text-xs text-right" style={{alignSelf:"flex-start"}}>
            {formatDate({ date: item.date, language })}
          </h6>
        )}
      </div>
      {item.summary && (
        <div className="mt-2 text-sm">{parse(item.summary)}</div>
      )}
    </div>
  );
    };

const AwardsWind = () => {
  const { data, heading: Heading } = useContext(PageContext);
  const variableTemplate = data.metadata.template;
  const layout = data.metadata.layout[variableTemplate];
  const whatCol = layout[0].indexOf("awards");
  const myBlock = (whatCol == "-1") ? "grid grid-cols-2 gap-2" : "grid gap-1";

  return safetyCheck(data.awards) ? (

    <div className={(visibleItems(data.awards.items)) ? "module award" : "erase"}>
      <Heading parent='awards'>{visibleItems(data.awards.items) && data.awards.heading}</Heading>
      <div className={myBlock}>
        {data.awards.items.map(
          (x) =>
            isItemVisible(x) && (
              <AwardItem
                key={x.id}
                item={x}
                language={data.metadata.language}
              />
            ),
        )}
      </div>
    </div>
  ) : null;
};

export default memo(AwardsWind);

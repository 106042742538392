import React from 'react';
import AwardsRock from './blocks/Awards/AwardsRock';
import ObjectiveRock from './blocks/Objective/ObjectiveRock';
import CertificationsRock from './blocks/Certifications/CertificationsRock';
import ContactRock from './blocks/Contact/ContactRock';
import EducationRock from './blocks/Education/EducationRock';
import HeadingRock from './blocks/Heading/HeadingRock';
import HobbiesRock from './blocks/Hobbies/HobbiesRock';
import LanguagesRock from './blocks/Languages/LanguagesRock';
import PageContext from '../contexts/PageContext';
import ProjectsA from './blocks/Projects/ProjectsA';
import ReferencesRock from './blocks/References/ReferencesRock';
import SkillsRock from './blocks/Skills/SkillsRock';
import WorkRock from './blocks/Work/WorkRock';


const Blocks = {
  objective: ObjectiveRock,
  work: WorkRock,
  education: EducationRock,
  projects: ProjectsA,
  awards: AwardsRock,
  certifications: CertificationsRock,
  skills: SkillsRock,
  hobbies: HobbiesRock,
  languages: LanguagesRock,
  references: ReferencesRock,
  contact:ContactRock,
};

const Rock = ({ data }) => {
  const layout = data.metadata.layout.rock;
  const variableTemplate = data.metadata.template;

  const Photo = () =>
    data.profile.photograph !== '' && (
      <img
        className="photo"
        style={{
          outlineColor : data.metadata.rock.background,
        }}
        src={data.profile.photograph}
        alt={data.profile.firstName}
      />
    );


  const Ufall = () =>
    data.metadata.trainingProgram.logo3 !== '' ? (
      <div className="grid grid-cols-2 gap-2 ufall">
        <div className="UFA">
          <img src={data.metadata.trainingProgram.logo1} style={{width:"90%"}} />
        </div>
        <div className="UFA">
          <img src={data.metadata.trainingProgram.logo2} style={{width:"90%"}} />
        </div>
        <div className="UFA">
          <img src={data.metadata.trainingProgram.logo3} style={{width:"90%"}} />
        </div>
        <div className="UFA">
          <img src={data.metadata.trainingProgram.logoAFi24} style={{width:"90%"}} />
        </div>
      </div>
    ): data.metadata.trainingProgram.logo2 !== '' ?(
      <div className="">
        <div className="UFA" style={{width:"45%",marginRight:"10px",display:"inline-block"}}>
          <img src={data.metadata.trainingProgram.logo1}/>
        </div>
        <div className="UFA" style={{width:"45%",display:"inline-block"}}>
          <img src={data.metadata.trainingProgram.logo2}/>
        </div>
        <div className="UFA" style={{width:"45%",margin:"0 auto", float:"left"}}>
          <img src={data.metadata.trainingProgram.logoAFi24}/>
        </div>
      </div>
    ): (
      <div className="grid grid-cols-2 gap-2 ufall">
        <div className="UFA">
          <img src={data.metadata.trainingProgram.logo1}/>
        </div>
        <div className="UFA">
          <img src={data.metadata.trainingProgram.logoAFi24}/>
        </div>
      </div>
    );
  
  return (

  <PageContext.Provider value={{ data, heading: HeadingRock }}>
    <div
      id="pageTemplate"
      // className="rounded"
      style={{
        fontFamily: data.metadata.font,
        color: data.metadata.rock.text,
        backgroundColor: data.metadata.rock.background,
      }}
    >
      
      <div id="rock" className="grid grid-cols-12">
        <div className="col-span-4 cg"
             style={{
               color: data.metadata.rock.text,
               backgroundColor: data.metadata.rock.primary,
               borderColor: data.metadata.rock.text,
             }}
        >
          <div className="p-5 grid gap-4" style={{height:"100%"}}>
            <div className="flex items-center">
                <Photo />
            </div>
            <div className='etablissement'>
              <div className='heading'>
                <h6 className="text-lg uppercase tracking-wide mb-2 title" style={{color: data.metadata[variableTemplate].text,fontWeight:'bold'}}>établissements</h6>
              </div>
              <Ufall/>
            </div>
            <ContactRock />
            {layout[0] &&
            layout[0].map((x) => {
              const Component = Blocks[x];
              return Component && <Component key={x} />;
            })}

          </div>
        </div>
        <div className="col-span-8 cd">

          <div className="p-5 grid gap-3" style={{height:"100%"}} >
            <div className="headerRock"
                 style={{
                   backgroundColor:  data.metadata.rock.text,
                   color: data.metadata.rock.background,
                 }}>
              <div className="flex column relative name">
                <h1 className="text-4xl leading-tight"
                    style={{
                      color: data.metadata.rock.primary,
                    }}>
                  {data.profile.firstName}
                </h1>
              </div>
              <div className="flex column relative name">
                <h1 className="text-4xl font-bold leading-tight uppercase"
                    style={{
                      color: data.metadata.rock.primary,
                    }}>
                  {data.profile.lastName}
                </h1>
              </div>
              <div className="text-sm font-medium tracking-wide sujet">
                {data.profile.subtitle}
              </div>
              <div className="font-bold text-xl formation">
                {data.metadata.trainingProgram.nomformation}
              </div>
            </div>
            {layout[1] &&
            layout[1].map((x) => {
              const Component = Blocks[x];
              return Component && <Component key={x} />;
            })}
          </div>
        </div>
      </div>
    </div>
  </PageContext.Provider>
  );
};


export default Rock;

import { FaCaretRight } from 'react-icons/fa';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import React, { memo, useContext } from 'react';
import { hasAddress, isItemVisible, safetyCheck } from '../../../utils';
import BirthDateLight from '../BirthDate/BirthDateLight';
import Icons from '../Icons';
import PageContext from '../../../contexts/PageContext';

const ContactItem = ({ value, icon, link, type }) => {
  const { data } = useContext(PageContext);
  const Icon = get(Icons, icon && icon.toLowerCase(), FaCaretRight);
  const variableTemplate = data.metadata.template;
  const rootPath = data.metadata[variableTemplate];
  const myBlock =(type == "first") ? "containIcon" : "noContainIcon";
  const myColor =(type == "first") ? rootPath.background : rootPath.primary;

  return value ? (
    <div className="text-xs flex items-center">
      <div className={myBlock}
           style={{ backgroundColor: data.metadata[variableTemplate].primary}}>
        <Icon
          size="11px"
          className="mr-2"
          style={{ color: myColor}}
        />
      </div>
      {link ? (
        <a href={link} target="_blank" rel="noopener noreferrer">
          <span className="break-all">{value}</span>
        </a>
      ) : (
        <span className="">{value}</span>
      )}
    </div>
  ) : null;
};

const ContactLine = ({}) => {
  const { t } = useTranslation();
  const { data } = useContext(PageContext);


  return (
    <div className="module contact">
    <div className="contactLine text-xs flex">
      <BirthDateLight />
      <ContactItem
        label={t('shared.forms.phone')}
        value={data.profile.phone}
        icon="phone"
        link={`tel:${data.profile.phone}`}
        type="first"
      />
      <ContactItem
        label={t('shared.forms.email')}
        value={data.profile.email}
        icon="email"
        link={`mailto:${data.profile.email}`}
        type="first"
      />
      {hasAddress(data.profile.address) && (
        <ContactItem
          label={t('shared.forms.address')}
          value={`${[
            data.profile.address.line1,
            data.profile.address.line2,
            data.profile.address.city,
          ]
            .filter(Boolean)
            .join(', ')} ${data.profile.address.pincode}`}
          icon="address"
          type="first"
        />
      )}
    </div>
    <div className="permisLine text-xs flex">
        <ContactItem
          label={t('shared.forms.drivingLicence')}
          value={data.profile.drivingLicence}
          icon="driving"
          type="second"
        />
        <ContactItem
          label={t('shared.forms.mobility')}
          value={`Mobilité : ${data.profile.mobility}`}
          icon="mobility"
          type="second"
        />
        {safetyCheck(data.social) &&
        data.social.items.map(
          (x) =>
            isItemVisible(x) && (
              <ContactItem
                key={x.id}
                value={x.username}
                icon={x.network}
                link={x.url}
                type="second"
              />
            ),
        )}
    </div>
    </div>
  );
};

export default memo(ContactLine);

import { FaCaretRight } from 'react-icons/fa';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import React, { memo, useContext } from 'react';
import { hasAddress, isItemVisible, safetyCheck } from '../../../utils';
import BirthDatePik from '../BirthDate/BirthDatePik';
import Icons from '../Icons';
import PageContext from '../../../contexts/PageContext';
import { useSelector } from '../../../contexts/ResumeContext';



const ContactItem = ({ value, icon, link }) => {
  const { data } = useContext(PageContext);
  const Icon = get(Icons, icon && icon.toLowerCase(), FaCaretRight);
  const variableTemplate = data.metadata.template;

  return value ? (
    <div className="flex items-center text-xs">
      <Icon
        size="11px"
        className="mr-2"
        style={{ color: data.metadata[variableTemplate].background}}
      />
      {link ? (
        <a href={link} target="_blank" rel="noopener noreferrer">
          <span className="break-all">{value}</span>
        </a>
      ) : (
        <span className="">{value}</span>
      )}
    </div>
  ) : null;
};

const ContactPik = () => {
  const { t } = useTranslation();
  const { data, heading: Heading } = useContext(PageContext);
  const variableTemplate = data.metadata.template;

  return (
    <div className="module contact" style={{width:"105%"}}>
      <Heading parent='languages'>{data.profile.heading}</Heading>
    <div className="text-xs grid gap-2">
      <ContactItem
        label={t('shared.forms.email')}
        value={data.profile.email}
        icon="email"
        link={`mailto:${data.profile.email}`}
      />
      <div className='grid grid-cols-2 gap-1'>
      <ContactItem
        label={t('shared.forms.phone')}
        value={data.profile.phone}
        icon="phone"
        link={`tel:${data.profile.phone}`}
      />
      <BirthDatePik />
      <ContactItem
        label={t('shared.forms.drivingLicence')}
        value={data.profile.drivingLicence}
        icon="driving"
      />
      <ContactItem
        label={t('shared.forms.mobility')}
        value={`Mobilité : ${data.profile.mobility}`}
        icon="mobility"
      />
      
      {safetyCheck(data.social) &&
        data.social.items.map(
          (x) =>
            isItemVisible(x) && (
              <ContactItem
                key={x.id}
                value={x.username}
                icon={x.network}
                link={x.url}
              />
            ),
        )}
      </div>
      {hasAddress(data.profile.address) && (
        <ContactItem
          label={t('shared.forms.address')}
          value={`${[
            data.profile.address.line1,
            data.profile.address.line2,
            data.profile.address.city,
          ]
            .filter(Boolean)
            .join(', ')} ${data.profile.address.pincode}`}
          icon="address"
        />
      )}
      </div>
      <div className="cgUnderline" style={{borderColor: data.metadata[variableTemplate].background,paddingBottom: "10px",borderBottom: "1px solid #fff"}}></div>
    </div>
  );
};

export default memo(ContactPik);

import { useTranslation } from 'react-i18next';
import React, { memo, useContext } from 'react';
import PageContext from '../../../contexts/PageContext';
import { formatDateRange, isItemVisible, safetyCheck, visibleItems } from "../../../utils";
import parse from "html-react-parser";

const ProjectItem = ({ item, language }) => {
  const { t } = useTranslation();
  return (
    <div className="ref text-left">
          <h6 className="font-bold text-sm">{item.title}</h6>
      {item.startDate && (
        <h6 className="text-xs" >
          {formatDateRange(
            {
              startDate: item.startDate,
              endDate: item.endDate,
              language,
            },
            t,
          )}
        </h6>
      )}
      {item.link && (
        <a href={item.link} className="text-xs">
          {item.link}
        </a>
      )}
      {item.summary && (
        <div className="text-sm">{parse(item.summary)}</div>
      )}
    </div>
  );
};

const ProjectsLight = () => {
  const { data, heading: Heading } = useContext(PageContext);

  return safetyCheck(data.projects) ? (
    <div className={(visibleItems(data.projects.items)) ? "module projects" : "erase"}>
      <Heading parent='projects'>{visibleItems(data.projects.items) && data.projects.heading}</Heading>
      <div className="grid">
        {data.projects.items.map(
          (x) =>
            isItemVisible(x) && (
              <ProjectItem
                key={x.id}
                item={x}
                language={data.metadata.language}
              />
            ),
        )}
      </div>
    </div>
  ) : null;
};

export default memo(ProjectsLight);

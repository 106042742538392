import { useTranslation } from 'react-i18next';
import React, { memo, useContext } from 'react';
import PageContext from '../../../contexts/PageContext';
import { formatDateRange, isItemVisible, safetyCheck, visibleItems } from "../../../utils";
import { FaBriefcase} from 'react-icons/fa';
import parse from "html-react-parser";

const WorkItem = ({ item, language }) => {
  const { t } = useTranslation();
  const {data} = useContext(PageContext);
  const variableTemplate = data.metadata.template;
  /*colG or colD*/
  const layout = data.metadata.layout[variableTemplate];
  const whatCol = layout[0].indexOf("work");

  const myCircle = (whatCol == "-1") ? "circle" : "";
  const myTitle = (whatCol == "-1") ? "font-bold text-sm wtitle" : "font-bold text-sm";
  const myContent = (whatCol == "-1") ? "wcontent" : "";

  return (
    <div className="ref">
      <div className="reference"> 
        <div className={myCircle} style={{backgroundColor: data.metadata[variableTemplate].primary}}></div>
        <h6 className={myTitle}>{item.company}</h6>
        {item.startDate && (
          <h6 className="text-xs font-bold text-right wdate" >
            {formatDateRange(
              {
                startDate: item.startDate,
                endDate: item.endDate,
                language,
              },
              t,
            )}
          </h6>
        )}
      </div>
      <div className={myContent} style={{borderColor: data.metadata[variableTemplate].primary}}>
        <span className="text-xs">{item.position}</span>
        {item.summary && (
          <div className="text-sm">{parse(item.summary)}</div>
        )}
        </div>
        
      </div>
  );
};

const WorkDra = () => {
  const { data} = useContext(PageContext);
  const Icon = FaBriefcase;
  const variableTemplate = data.metadata.template;
  return safetyCheck(data.work) ? (
    <div className={(visibleItems(data.work.items)) ? "module work" : "erase"}>
      <div className='flex items-center'>
      <Icon
        size="1.15em"
        className="mr-2"
        style={{ color: data.metadata[variableTemplate].primary}}
      />
      <h6 className="text-lg font-bold uppercase" style={{ color: data.metadata[variableTemplate].text}} >
        {data.work.heading}
      </h6>
      </div>
      <div className="grid gap-2 contain">
        {data.work.items.map(
          (x) =>
            isItemVisible(x) && (
              <WorkItem key={x.id} item={x} language={data.metadata.language} />
            ),
        )}
      </div>
    </div>
  ) : null;
};

export default memo(WorkDra);

import { useTranslation } from 'react-i18next';
import React, { memo, useContext } from 'react';
import PageContext from '../../../contexts/PageContext';
import { formatDateRange, isItemVisible, safetyCheck, visibleItems } from "../../../utils";
import parse from "html-react-parser";



const EducationItem = ({ item, language, }) => {
  const { t } = useTranslation();
  const {data} = useContext(PageContext);
  const variableTemplate = data.metadata.template;
  const layout = data.metadata.layout[variableTemplate];
  const whatCol = layout[0].indexOf('education');

  return (
    <div className="ref">
      <div className="flex justify-between items-center">
        <div className="flex flex-col text-left mr-2" style={{maxWidth:"75%"}}>
          <h6 className="font-bold text-sm">{item.degree} {item.field}</h6>
          <span className="text-xs">
           {item.institution}
          </span>
        </div>
        <div className="flex flex-col items-end self-start text-right">
          {item.startDate && (
            <h6 className="text-xs text-right">
              {formatDateRange(
                {
                  startDate: item.startDate,
                  endDate: item.endDate,
                  language,
                },
                t,
              )}
            </h6>
          )}
          <div className="text-sm font-bold">{item.gpa && (<span>Mention : {item.gpa}</span>)}</div>
        </div>
      </div>
      {item.summary.length > 7 && (
        <div className="mt-2 text-sm">{parse(item.summary)}</div>
      )}
      <div className="underline"></div>
    </div>
  );
};

const EducationRock = () => {
  const { data, heading: Heading } = useContext(PageContext);
  const variableTemplate = data.metadata.template;
  /*colG or colD*/
  const layout = data.metadata.layout[variableTemplate];
  const whatCol = layout[0].indexOf("education");
  const myBorder = (whatCol == "-1") ? "cgUnderNone" : "cgUnderline";


  return safetyCheck(data.education) ? (
    <div className={(visibleItems(data.education.items)) ? "module education" : "erase"}>
      <Heading parent='education'>{visibleItems(data.education.items) && data.education.heading}</Heading>
      <div className="grid gap-2">
        {data.education.items.map(
          (x) =>
            isItemVisible(x) && (
              <EducationItem
                key={x.id}
                item={x}
                language={data.metadata.language}
                template={data.metadata.template}
              />
            ),
        )}
      </div>
      <div className={myBorder} style={{borderColor: data.metadata[variableTemplate].text}}></div>
    </div>
  ) : null;
};

export default memo(EducationRock);
